import React from "react";
import CrudForm from "../../components/CrudForm/CrudForm";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import { BASE_ROUTES } from "../../config/baseRoutesConfig";

const FIELDS_CONFIG = [
	{
		key: "BASIC_SETTINGS",
		fields: [
			{
				name: "displayName",
				label: localeContent.DISPLAY_NAME.LABEL,
				tooltip: localeContent.DISPLAY_NAME.TOOLTIP,
				gridConfig: { md: 6 },
				isRequired: true
			},
			{
				name: "defaultName",
				label: localeContent.DEFAULT_NAME.LABEL,
				tooltip: localeContent.DEFAULT_NAME.TOOLTIP,
				gridConfig: { md: 6 },
				readOnly: true
			},
			{
				name: "allowedCompanyIds",
				label: localeContent.ALLOWED_ACCESS.LABEL,
				tooltip: localeContent.ALLOWED_ACCESS.TOOLTIP,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.MULTISELECT,
				optionsConfig: {
					requestEndpoint: "manage/companies"
				},
				defaultValue: []
			}
		]
	}
];

function DataDistributionResource() {
	return (
		<CrudForm
			resourceEndpoint="manage/dataDistributions"
			resourceRoute={BASE_ROUTES.dataDistributions}
			resourceString={commonLocaleContent.DATA_DISTRIBUTION}
			fieldsConfig={FIELDS_CONFIG}
			entityNameKey="displayName"
			preSubmit={(formValues, resourceId) => ({
				...formValues,
				id: resourceId
			})}
		/>
	);
}

export default DataDistributionResource;
