import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
	Box,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TablePagination,
	Paper,
	TableSortLabel,
	TableFooter,
	Typography
} from "@mui/material";
import { v4 as uuid } from "uuid";
import {
	formatAsCurrency,
	formatAsNumberWithSeparator,
	formatAsDate,
	formatAsEnabled,
	formatAsCapitalized
} from "../../utils/formatters";
import DataTablePaginationActions from "./DataTablePaginationActions";
import SearchTextField from "../SearchTextField/SearchTextField";
import CheckboxMenu from "../CheckboxMenu/CheckboxMenu";
import {
	getComparator,
	stableSort,
	getOppositeOrder
} from "../../utils/sortUtil";
import localeContent from "./localeContent";
import { MUI_PADDING_NORMAL } from "../../config/constants";
import { isEmpty } from "../../utils/objectUtils";

// Create an map of column dataType enums to their alignment and formatting configuration
const CELL_DATA_TYPES = {
	currency: {
		formatter: formatAsCurrency,
		align: "right",
		isNumeric: true
	},
	number: {
		formatter: formatAsNumberWithSeparator,
		align: "right",
		isNumeric: true
	},
	date: {
		formatter: formatAsDate,
		align: "right",
		isNumeric: true
	},
	enabled: {
		formatter: formatAsEnabled
	},
	upperCase: {
		formatter: formatAsCapitalized
	}
};

function getCellAlignment(columnDataType, align) {
	const columnDataTypeConfig = CELL_DATA_TYPES[columnDataType];
	if (!columnDataTypeConfig) {
		return align || "left";
	}
	return columnDataTypeConfig.align;
}

function getCellFormatter(columnDataType, columnFormatter) {
	if (columnFormatter) {
		return columnFormatter;
	}
	const columnDataTypeConfig = CELL_DATA_TYPES[columnDataType];
	if (!columnDataTypeConfig) {
		return value => value;
	}
	return columnDataTypeConfig.formatter;
}

function getDefaultSortOrderForColumn(columnConfig = {}) {
	const defaultColumnSortDirection =
		CELL_DATA_TYPES[columnConfig.cellDataType] &&
		CELL_DATA_TYPES[columnConfig.cellDataType].isNumeric
			? "desc"
			: "asc";
	return columnConfig.sortDirection || defaultColumnSortDirection;
}

function getFilteredRowData(useSearch, rowData, searchQuery, searchColumnKey) {
	if (!useSearch) {
		return rowData;
	}
	const lowercaseSearch = searchQuery.toLowerCase();
	return rowData.filter(row => {
		return (
			row[searchColumnKey].toLowerCase().includes(lowercaseSearch) ||
			row.id.toString() === lowercaseSearch ||
			row.guid === searchQuery
		);
	});
}

const rowsPerPageOptions = [5, 10, 15, 25];

// If our current page is outside the possible range for the filtered data set, set the page to the new "last page"
const getSafePage = (filteredRowDataLength, rowsPerPage, page) => {
	if (page * rowsPerPage >= filteredRowDataLength) {
		return Math.max(0, Math.ceil(filteredRowDataLength / rowsPerPage) - 1);
	}
	return page;
};

const isColumnNumeric = columnConfig =>
	Boolean(CELL_DATA_TYPES[columnConfig?.cellDataType]?.isNumeric);

const getInitialSortState = (columnsConfig, defaultSortColumnKey) => {
	const sortColumnConfig = columnsConfig.find(
		column => column.key === defaultSortColumnKey
	);
	return {
		order: getDefaultSortOrderForColumn(sortColumnConfig),
		orderByColumnKey: defaultSortColumnKey,
		isNumeric: isColumnNumeric(sortColumnConfig)
	};
};

function DataTable(props) {
	const {
		heading,
		columnsConfig,
		rowData,
		disclaimer,
		accessibleTableDescription,
		paginationConfig,
		useSearch,
		defaultSortColumnKey,
		searchColumnKey,
		tableActionButton,
		itemCountLabel,
		disableColumnToggleMenu
	} = props;

	const isPaginationDefined = Boolean(paginationConfig);
	// Use pagination config, or defaults
	const { defaultRowsPerPage } = {
		defaultRowsPerPage: 15,
		...paginationConfig
	};
	const [sortConfig, setSortConfig] = React.useState(
		getInitialSortState(columnsConfig, defaultSortColumnKey)
	);
	const [page, setPage] = React.useState(0);
	// NOTE: "No pagination" will only work with static options (there's a bug when fetching options)
	// If no pagination is being used, show the whole dataset
	const [rowsPerPage, setRowsPerPage] = React.useState(
		isPaginationDefined ? defaultRowsPerPage : rowData.length
	);
	const [showColumnsConfig, setShowColumnsConfig] = React.useState(
		columnsConfig.reduce((aggregator, column) => {
			return { [column.key]: true, ...aggregator };
		}, {})
	);
	const [searchQuery, setSearchQuery] = React.useState("");

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleColumnToggle = columnKey => () => {
		setShowColumnsConfig({
			...showColumnsConfig,
			[columnKey]: !showColumnsConfig[columnKey]
		});
	};

	const handleSearchQueryChange = newSearchQuery => {
		setSearchQuery(newSearchQuery);
	};

	const handleRequestSort = useCallback(
		clickedColumnKey => () => {
			const sortColumnConfig = columnsConfig.find(
				column => column.key === clickedColumnKey
			);
			const sortOrder =
				clickedColumnKey === sortConfig.orderByColumnKey
					? getOppositeOrder(sortConfig.order)
					: getDefaultSortOrderForColumn(sortColumnConfig);
			setSortConfig({
				order: sortOrder,
				orderByColumnKey: clickedColumnKey,
				isNumeric: isColumnNumeric(sortColumnConfig)
			});
		},
		[columnsConfig, sortConfig]
	);

	const filteredRowData = getFilteredRowData(
		useSearch,
		rowData,
		searchQuery,
		searchColumnKey
	);
	// Because we allow deletions and search, we need to make sure the page doesn't exceed the max possible page (if you delete the last item on a page)
	const safePage = getSafePage(filteredRowData.length, rowsPerPage, page);

	// Empty rows are added to maintain vertical positioning of the pagination tool while changing pages
	const emptyRows =
		rowsPerPage -
		Math.min(rowsPerPage, filteredRowData.length - safePage * rowsPerPage);
	const { order, orderByColumnKey, isNumeric } = sortConfig;
	const displayTableControls = useSearch || !disableColumnToggleMenu;
	const uniqueID = uuid();
	return (
		<Box my={3} px={2} pt={1} component={Paper}>
			{itemCountLabel && rowData.length > 0 && (
				<Box display="flex" flexDirection="row">
					<Box flexGrow={1} />
					{`${itemCountLabel} Count: ${rowData.length}`}
				</Box>
			)}

			{heading && (
				<Box display="flex" flexDirection="row">
					<Typography id={uniqueID} variant="subtitle1" sx={{ mt: 1 }}>
						{heading}
					</Typography>
				</Box>
			)}
			{displayTableControls && (
				<Box display="flex" flexDirection="row" sx={{ minHeight: "48px" }}>
					{useSearch && (
						<SearchTextField
							searchQueryValue={searchQuery}
							onSearchQueryValueChange={handleSearchQueryChange}
						/>
					)}

					<Box flexGrow={1} flexShrink={2} />
					{!disableColumnToggleMenu && (
						<CheckboxMenu
							menuId="show-hide-columns"
							menuButtonLabel={localeContent.SHOW_HIDE_COLUMN_MENU_LABEL}
							menuOptions={columnsConfig
								.filter(column => !column.preventVisibilityToggle)
								.map(({ name, key }) => ({ name, key }))}
							selectedOptions={showColumnsConfig}
							handleMenuOptionToggle={handleColumnToggle}
						/>
					)}
				</Box>
			)}

			<TableContainer>
				<Table
					aria-label={!heading ? accessibleTableDescription : undefined}
					aria-labelledby={heading ? uniqueID : undefined}
					size="small"
					sx={{ minWidth: "960px" }}
				>
					<TableHead>
						<TableRow>
							{columnsConfig.map(
								column =>
									showColumnsConfig[column.key] && (
										<TableCell
											sx={{
												bgcolor: "primary.800",
												color: "common.white"
											}}
											key={column.key}
											align={getCellAlignment(
												column.cellDataType,
												column.align
											)}
											sortDirection={
												!column.preventSort && orderByColumnKey === column.key
													? order
													: false
											}
											scope="col"
											padding={column.padding || MUI_PADDING_NORMAL}
											data-testid={column.key}
										>
											<TableSortLabel
												active={orderByColumnKey === column.key}
												direction={
													orderByColumnKey === column.key
														? order
														: getDefaultSortOrderForColumn(column)
												}
												onClick={
													column.preventSort
														? undefined
														: handleRequestSort(column.key)
												}
												hideSortIcon={column.preventSort}
											>
												{column.name}
												{orderByColumnKey === column.key ? (
													<span className="sr-only-text">
														{order === "desc"
															? "sorted descending"
															: "sorted ascending"}
													</span>
												) : null}
											</TableSortLabel>
										</TableCell>
									)
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{stableSort(
							filteredRowData,
							getComparator(order, orderByColumnKey, isNumeric)
						)
							.slice(
								safePage * rowsPerPage,
								safePage * rowsPerPage + rowsPerPage
							)
							.map(row => (
								<TableRow
									key={row.id}
									data-testid={row.id}
									sx={{
										"&:nth-of-type(even)": {
											backgroundColor: "secondary.300",
											color: "common.black"
										}
									}}
								>
									{columnsConfig.map((column, index) => {
										const isFirstColumn = index === 0;
										return (
											showColumnsConfig[column.key] && (
												<TableCell
													key={column.key}
													align={getCellAlignment(
														column.cellDataType,
														column.align
													)}
													// First column's row cell should be a row header for accessiblity
													component={isFirstColumn ? "th" : undefined}
													scope={isFirstColumn ? "row" : undefined}
													data-columnname={column.key}
													padding={column.padding || MUI_PADDING_NORMAL}
												>
													{getCellFormatter(
														column.cellDataType,
														column.formatter
													)(row[column.key], row)}
												</TableCell>
											)
										);
									})}
								</TableRow>
							))}
						{// Magical height number comes from MUI docs https://material-ui.com/components/tables/#sorting-amp-selecting
						emptyRows > 0 && (
							<TableRow style={{ height: 33 * emptyRows }}>
								<TableCell colSpan={columnsConfig.length} />
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell colSpan="1000">
								<Box sx={{ display: "flex", alignItems: "center" }}>
									<Box sx={{ flexGrow: 1, flexShrink: 1 }}>
										{!isEmpty(rowData) && tableActionButton}
										{disclaimer && (
											<Typography variant="caption">{disclaimer}</Typography>
										)}
									</Box>
									{paginationConfig && (
										<TablePagination
											rowsPerPageOptions={rowsPerPageOptions}
											component="div"
											count={filteredRowData.length}
											rowsPerPage={rowsPerPage}
											page={safePage}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
											ActionsComponent={DataTablePaginationActions}
											SelectProps={{
												labelId: "rows-per-page-label",
												name: "rowsPerPage"
											}}
											labelRowsPerPage={
												<span id="rows-per-page-label">Rows per page:</span>
											}
										/>
									)}
								</Box>
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</Box>
	);
}

DataTable.propTypes = {
	heading: PropTypes.string,
	columnsConfig: PropTypes.arrayOf(PropTypes.shape).isRequired,
	accessibleTableDescription: PropTypes.string,
	rowData: PropTypes.arrayOf(PropTypes.shape),
	disclaimer: PropTypes.string,
	paginationConfig: PropTypes.shape({
		defaultRowsPerPage: PropTypes.number
	}),
	useSearch: PropTypes.bool,
	defaultSortColumnKey: PropTypes.string,
	searchColumnKey: PropTypes.string,
	tableActionButton: PropTypes.node,
	itemCountLabel: PropTypes.string,
	disableColumnToggleMenu: PropTypes.bool
};

DataTable.defaultProps = {
	heading: "",
	accessibleTableDescription: null,
	rowData: [],
	paginationConfig: null,
	disclaimer: "",
	useSearch: true,
	defaultSortColumnKey: "id",
	searchColumnKey: "name",
	tableActionButton: null,
	itemCountLabel: null,
	disableColumnToggleMenu: false
};

export default DataTable;
