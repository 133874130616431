import React from "react";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import commonLocaleContent from "../commonLocaleContent";

const insertionOrderColumnsConfig = [
	{ name: commonLocaleContent.ADVERTISER, key: "advName" },
	{
		name: commonLocaleContent.COLUMN_HEADER_STATUS,
		key: COLUMN_KEY_STATUS,
		cellDataType: "upperCase"
	},
	{
		name: commonLocaleContent.COLUMN_HEADER_IMPRESSIONS,
		key: "impressions",
		cellDataType: "number"
	},
	{
		name: commonLocaleContent.COLUMN_HEADER_GROSS_REVENUE,
		key: "grossRevenue",
		cellDataType: "currency"
	}
];

const insertionChildResourceConfig = {
	name: "Campaign",
	route: "campaigns",
	deleteAssociatedResourceWarning: "campaigns and media"
};

function InsertionOrderIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.INSERTION_ORDER}
			columnsConfig={insertionOrderColumnsConfig}
			disclaimer={commonLocaleContent.DEMAND_INDEX_SCREEN_DISCLAIMER_CAPTION}
			resourceEndpoint="manage/insertion-orders"
			resourceRoute="insertion-orders"
			childResourceConfig={insertionChildResourceConfig}
			parentId="advId"
			showDataGrid
		/>
	);
}

export default InsertionOrderIndex;
