export default {
	COMPANY_NAME: {
		LABEL: "Company Name"
	},
	COMPANY_DOMAIN: {
		LABEL: "Company Domain",
		TOOLTIP: "Company's corporate website"
	},
	CONFIDENTIAL: {
		LABEL: "Is Confidential",
		TOOLTIP:
			"If Yes, is_confidential will be set to 1 in sellers.json and limited information will be included"
	},
	SHOW_HELP_TEXT: {
		LABEL: "Show Help Links"
	},
	SERVER_SIDE_REQUEST: {
		LABEL: "Server-side Requests",
		TOOLTIP:
			"If enabled, all tags, both managed and programmatic, will be requested server-side to extract relevant information"
	},
	PRIVACY_POLICY_URL: {
		LABEL: "Privacy Policy URL"
	},
	TC_URL: {
		LABEL: "Terms and Conditions URL"
	},
	ROOT_DOMAIN: {
		LABEL: "Root Domain"
	},
	ARENA_DOMAIN: {
		LABEL: "Ad Server"
	},
	MOBILE_DOMAIN: {
		LABEL: "Mobile Ad Server"
	},
	EVENT_DOMAIN: {
		LABEL: "Event"
	},
	RTB_DOMAIN: {
		LABEL: "RTB"
	},
	COOKIE_DOMAIN: {
		LABEL: "Cookies"
	},
	PUBLISHER_DOMAIN: {
		LABEL: "Publisher"
	},
	MANAGE_DOMAIN: {
		LABEL: "Manage"
	},
	MEDIA_DOMAIN: {
		LABEL: "Media"
	},
	CDN_DOMAIN: {
		LABEL: "CDN"
	},
	PLATFORM_LOGO: {
		BUTTON_LABEL: "Upload Platform Logo",
		PREVIEW_LABEL: "Platform Logo Preview"
	},
	EMAIL_LOGO: {
		BUTTON_LABEL: "Upload Email Logo",
		PREVIEW_LABEL: "Email Logo Preview"
	},
	FAVICON: {
		BUTTON_LABEL: "Upload Favicon",
		PREVIEW_LABEL: "Favicon"
	},
	SUPPORT_EMAIL: {
		LABEL: "Support Email"
	},
	REPORTING_EMAIL: {
		LABEL: "Reporting Email"
	},
	RTB_LABEL: "RTB",
	NETWORK_LABEL: "Network",
	CAMPAIGN_LABEL: "Campaign",
	PUBLISHER_LABEL: "Publisher",
	ENABLE: "Enable",
	DISABLE: "Disable",
	REPORTING_LIMIT_DIMENSIONS: {
		LABEL: ({ type }) => `${type} - Limit Dimensions`,
		RANGE_LABEL: ({ type }) => `${type} - Limit Dimensions Max Range`,
		TOOLTIP:
			"Specified dimensions can only be queried for a certain date range",
		OPTION_LABELS: {
			DAY: "Day",
			HOUR: "Hour",
			PUBLISHER: "Publisher",
			SITE: "Site",
			PLACEMENT: "Placement",
			INSERTION_ORDER: "Insertion Order",
			CAMPAIGN: "Campaign",
			MEDIA: "Media",
			IAB_CATEGORY: "IAB Category",
			OPP_TYPES: "Opportunity Filters",
			DOMAIN: "Domain",
			BUYER: "Advertiser",
			BUYERS: "Demand Partner",
			SIZE: "Size",
			ACTUAL_SIZE: "Actual Size",
			TRUE_URL: "True URL",
			MEDIA_TYPE: "Demand Type",
			DEVICE: "Device",
			OPERATING_SYSTEM: "Operating System",
			APP_NAME: "App Name",
			APP_BUNDLE: "App Bundle",
			FLOOR: "Floor",
			GEO: "Country",
			STATE: "Region/State",
			DMA: "DMA",
			CITY: "City",
			CAMPAIGN_ID: "Campaign ID",
			CREATIVE_ID: "Creative ID",
			ADVERTISER_ID: "Advertiser ID",
			ADVERTISER_DOMAIN: "Advertiser Domain",
			DEAL_ID: "Deal ID",
			SEAT_ID: "Seat ID",
			POD_SEQUENCE: "Pod Sequence",
			CONTENT_GENRE: "Content Genre"
		}
	},
	DEFAULT_REV_SHARE: {
		LABEL: "Default Publisher Revenue Share"
	},
	DEFAULT_MARGIN: {
		LABEL: "Default Margin"
	},
	MIN_BITRATE: {
		LABEL: "Default Min Bitrate",
		TOOLTIP:
			"This will be the default minbitrate for all new Placements belonging to this company"
	},
	MAX_BITRATE: {
		LABEL: "Default Max Bitrate",
		TOOLTIP:
			"This will be the default maxbitrate for all new Placements belonging to this company"
	},
	ALLOW_IVT_FILTERING: {
		LABEL: "Allow IVT Filtering",
		TOOLTIP:
			"If enabled, all CTV placements belonging to this company will have the option to filter out invalid traffic"
	},
	ENFORCE_IVT_FILTERING: {
		LABEL: "Enforce IVT Filtering",
		TOOLTIP:
			"If enabled, all CTV placements belonging to this company will monitor for IVT, even if IVT Filtering is disabled at the placement level"
	},
	IVT_PROBABILITY_THRESHOLD: {
		LABEL: "IVT Probability Threshold",
		TOOLTIP:
			"The probability that an opportunity is invalid in order for that opportunity to be blocked"
	},
	PRIMARY_COLOR: {
		LABEL: "Primary Color",
		HELPER_TEXT: "Color palettes can be viewed",
		LINK_TEXT: "here",
		TOOLTIP:
			"Select the color palette which should be used across the company’s platform instance."
	},
	SUPPRESS_DISPLAY_CLICKTHROUGH: {
		LABEL: "Suppress Display Clickthrough",
		TOOLTIP:
			"If enabled, clicks on Display Placements will not redirect the user to the associated click through destination"
	},
	MEDIA_PRIORITIZATION_TYPE: {
		LABEL: "Media Prioritization Type",
		TOOLTIP:
			"If Priority is selected, Media will be prioritized based on the Priority and Weight settings in the Media form. If CPM is selected, Media will be prioritized based on the Media CPM",
		OPTIONS: {
			PRIORITY: {
				LABEL: "Priority"
			},
			CPM: {
				LABEL: "CPM"
			}
		}
	},
	BID_REQUEST_MULTIPLIER: {
		LABEL: "Bid Request Multiplier"
	},
	OPPORTUNITY_COST_MULTIPLIER: {
		LABEL: "Opportunity Cost Multiplier"
	},
	BIDDER_SERVICE_ENABLED: {
		LABEL: "Bidder Placements",
		TOOLTIP:
			"If enabled, Placements belonging to this Company will be allowed to use the Siprocal Bidder service"
	},
	TRAFFIC_SHAPING_ENABLED: {
		LABEL: "Traffic Shaping",
		TOOLTIP:
			"When enabled, poor-performing inventory will not be exposed to less programmatic demand"
	}
};
