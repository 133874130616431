import React from "react";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import commonLocaleContent from "../commonLocaleContent";
import { ACTION_MENU_ITEM_TYPES } from "../../components/GenericTableRowActionMenu/GenericTableRowActionMenu";
import { SERVER_URL_WITH_VERSION } from "../../config/constants";

const sitesColumnsConfig = [
	{ name: commonLocaleContent.PUBLISHER, key: "publisherName" },
	{
		name: commonLocaleContent.COLUMN_HEADER_STATUS,
		key: COLUMN_KEY_STATUS,
		cellDataType: "upperCase"
	},
	{
		name: commonLocaleContent.COLUMN_HEADER_OPPORTUNITIES,
		key: "opportunities",
		cellDataType: "number"
	},
	{
		name: commonLocaleContent.COLUMN_HEADER_IMPRESSIONS,
		key: "impressions",
		cellDataType: "number"
	},
	{
		name: commonLocaleContent.COLUMN_HEADER_REVENUE,
		key: "revenue",
		cellDataType: "currency"
	}
];

const siteChildResourceConfig = {
	name: "Placement",
	route: "placements",
	deleteAssociatedResourceWarning: "placements"
};

const tableRowActionMenuCustomOptions = [
	{
		key: "TAGS-TXT",
		label: commonLocaleContent.ACTION_BUTTON_LABEL_DOWNLOAD_TAGS_TXT,
		accessibilityLabel:
			commonLocaleContent.ACCESSIBILITY_LABEL_TRIGGERS_DOWNLOAD,
		getHref: ({ id }) =>
			`${SERVER_URL_WITH_VERSION}/manage/sites/${id}/tag_file`,
		download: "tags.txt",
		type: ACTION_MENU_ITEM_TYPES.externalLink
	}
];

function SiteIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.SITE}
			columnsConfig={sitesColumnsConfig}
			resourceEndpoint="manage/sites"
			resourceRoute="sites"
			parentId="publisherId"
			disclaimer={commonLocaleContent.SUPPLY_INDEX_SCREEN_DISCLAIMER_CAPTION}
			childResourceConfig={siteChildResourceConfig}
			tableRowActionMenuCustomOptions={tableRowActionMenuCustomOptions}
			showDataGrid
		/>
	);
}

export default SiteIndex;
