import React from "react";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import { BASE_ROUTES } from "../../config/baseRoutesConfig";
import commonLocaleContent from "../commonLocaleContent";
import localeContent from "./localeContent";

const columnsConfig = [
	{
		key: "defaultName",
		name: localeContent.COLUMN_DEFAULT_NAME
	}
];

function DataDistributionIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.DATA_DISTRIBUTION}
			columnsConfig={columnsConfig}
			resourceEndpoint="manage/dataDistributions"
			resourceRoute={BASE_ROUTES.dataDistributions}
			primaryColumnConfig={{
				key: "displayName",
				name: localeContent.COLUMN_DISPLAY_NAME
			}}
			disableAdd
			disableDelete
			disableClone
			showDataGrid
		/>
	);
}

export default DataDistributionIndex;
