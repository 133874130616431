import React, { useContext } from "react";
import * as Yup from "yup";
import CrudForm from "../../components/CrudForm/CrudForm";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import { AuthenticatedUserSettingsContext } from "../../context/AuthenticatedUserSettingsContext";
import { BASE_ROUTES } from "../../config/baseRoutesConfig";
import {
	defaultCompanyId,
	DENSITY_TYPES,
	UTC_TIME_ZONE
} from "../../config/constants";
import { CAN_SWITCH_COMPANIES_PERMISSION } from "../../components/Navigation/Navigation";

const mapEntriesToOptions = ([key, value]) => ({ id: key, name: value });

const min4Max8ItemsRequiredSchema = Yup.array()
	.min(4, localeContent.DEFAULT_KPIS.MIN_VALIDATION_MESSAGE)
	.max(8, localeContent.DEFAULT_KPIS.MAX_VALIDATION_MESSAGE);

const FIELDS_CONFIG = [
	{
		key: "BASIC_SETTINGS",
		fields: [
			{
				name: "firstName",
				label: localeContent.FIRST_NAME_LABEL,
				gridConfig: { md: 6 }
			},
			{
				name: "lastName",
				label: localeContent.LAST_NAME_LABEL,
				gridConfig: { md: 6 }
			},
			{
				name: "phoneNumber",
				label: localeContent.PHONE_NUMBER_LABEL,
				gridConfig: { md: 6 }
			},
			{
				name: "email",
				label: localeContent.EMAIL_ADDRESS_LABEL,
				gridConfig: { md: 6 },
				isRequired: true
			},
			{
				name: "timeZone",
				label: localeContent.TIMEZONE_LABEL,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.COMBO_BOX,
				optionsConfig: {
					requestEndpoint: "manage/catalogue/timezones",
					sortOptionsByName: false
				},
				defaultValue: UTC_TIME_ZONE
			},
			{
				name: "hideInactiveItems",
				permissionRequired: "VIEW_USER_PREFERENCES_ADVANCED",
				label: localeContent.HIDE_INACTIVE_ITEMS_LABEL,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.SWITCH,
				defaultValue: false
			}
		]
	},
	{
		key: "GENERAL_PREFERENCES",
		fields: [
			{
				name: "defaultActiveCompany",
				label: localeContent.DEFAULT_COMPANY_VIEW,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.COMBO_BOX,
				optionsConfig: {
					requestEndpoint: "manage/companies/select",
					sortOptionsByName: false
				},
				defaultValue: defaultCompanyId,
				permissionRequired: CAN_SWITCH_COMPANIES_PERMISSION
			},
			{
				name: "defaultDensity",
				label: localeContent.DEFAULT_DENSITY.FIELD_LABEL,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.SELECT,
				tooltip: localeContent.DEFAULT_DENSITY.TOOLTIP,
				optionsConfig: {
					options: [
						{
							value: DENSITY_TYPES.COMPACT,
							label: localeContent.DEFAULT_DENSITY.OPTIONS.COMPACT
						},
						{
							value: DENSITY_TYPES.STANDARD,
							label: localeContent.DEFAULT_DENSITY.OPTIONS.STANDARD
						},
						{
							value: DENSITY_TYPES.COMFORTABLE,
							label: localeContent.DEFAULT_DENSITY.OPTIONS.COMFORTABLE
						}
					]
				},
				defaultValue: DENSITY_TYPES.STANDARD
			}
		]
	},
	{
		key: "REPORTING_PREFERENCES",
		fields: [
			{
				name: "defaultNetworkMetrics",
				label: localeContent.DEFAULT_NETWORK_METRICS_LABEL,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.MULTISELECT,
				optionsConfig: {
					requestEndpoint: "reports/scheduled-reports/NETWORK/metrics",
					mapEntries: mapEntriesToOptions
				}
			},
			{
				name: "defaultNetworkDimensions",
				label: localeContent.DEFAULT_NETWORK_DIMENSIONS_LABEL,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.MULTISELECT,
				tooltip:
					"The order of the dimensions you select is the same order they will appear in the chart.",
				optionsConfig: {
					requestEndpoint: "reports/scheduled-reports/NETWORK/slices",
					mapEntries: mapEntriesToOptions
				}
			},
			{
				name: "defaultCampaignMetrics",
				label: localeContent.DEFAULT_CAMPAIGN_METRICS_LABEL,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.MULTISELECT,
				optionsConfig: {
					requestEndpoint: "reports/scheduled-reports/CAMPAIGN/metrics",
					mapEntries: mapEntriesToOptions
				}
			},
			{
				name: "defaultCampaignDimensions",
				label: localeContent.DEFAULT_CAMPAIGN_DIMENSIONS_LABEL,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.MULTISELECT,
				tooltip: localeContent.DEFAULT_DIMENSIONS_TOOLTIP,
				optionsConfig: {
					requestEndpoint: "reports/scheduled-reports/CAMPAIGN/slices",
					mapEntries: mapEntriesToOptions
				}
			},
			{
				name: "defaultRtbMetrics",
				label: localeContent.DEFAULT_RTB_METRICS_LABEL,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.MULTISELECT,
				optionsConfig: {
					requestEndpoint: "reports/scheduled-reports/RTB/metrics",
					mapEntries: mapEntriesToOptions
				}
			},
			{
				name: "defaultRtbDimensions",
				label: localeContent.DEFAULT_RTB_DIMENSIONS_LABEL,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.MULTISELECT,
				tooltip: localeContent.DEFAULT_DIMENSIONS_TOOLTIP,
				optionsConfig: {
					requestEndpoint: "reports/scheduled-reports/RTB/slices",
					mapEntries: mapEntriesToOptions
				}
			}
		]
	},
	{
		key: "Default KPIs",
		fields: [
			{
				name: "defaultNetworkKpis",
				label: localeContent.DEFAULT_KPIS.NETWORK_LABEL,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.MULTISELECT,
				tooltip: localeContent.DEFAULT_KPIS.TOOLTIP,
				optionsConfig: {
					requestEndpoint: "reports/scheduled-reports/NETWORK/metrics",
					mapEntries: mapEntriesToOptions,
					sortOptionsByName: false
				},
				validationSchema: min4Max8ItemsRequiredSchema
			},
			{
				name: "defaultCampaignKpis",
				label: localeContent.DEFAULT_KPIS.CAMPAIGN_LABEL,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.MULTISELECT,
				tooltip: localeContent.DEFAULT_KPIS.TOOLTIP,
				optionsConfig: {
					requestEndpoint: "reports/scheduled-reports/CAMPAIGN/metrics",
					mapEntries: mapEntriesToOptions,
					sortOptionsByName: false
				},
				validationSchema: min4Max8ItemsRequiredSchema
			},
			{
				name: "defaultRtbKpis",
				label: localeContent.DEFAULT_KPIS.RTB_LABEL,
				gridConfig: { md: 6 },
				fieldType: FieldTypes.MULTISELECT,
				tooltip: localeContent.DEFAULT_KPIS.TOOLTIP,
				optionsConfig: {
					requestEndpoint: "reports/scheduled-reports/RTB/metrics/",
					mapEntries: mapEntriesToOptions,
					sortOptionsByName: false
				},
				validationSchema: min4Max8ItemsRequiredSchema
			}
		]
	}
];

function UserSettingsResource() {
	const { setUserSettingsData } = useContext(AuthenticatedUserSettingsContext);

	return (
		<CrudForm
			resourceEndpoint="manage/userSettings"
			resourceRoute={BASE_ROUTES.userSettings}
			resourceString={commonLocaleContent.USER_SETTINGS}
			fieldsConfig={FIELDS_CONFIG}
			entityNameKey="firstName"
			isSingleView
			postSubmit={formData => setUserSettingsData(formData)}
		/>
	);
}

export default UserSettingsResource;
