import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState
} from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { getAuthenticatedEndpoint } from "../utils/endpointUtils";
import { FetchContext } from "./FetchContext";
import { AuthContext } from "./AuthContext";
import { METRICS_DEFAULT_VALUES } from "../config/metricsList";

import { DENSITY_TYPES, UTC_TIME_ZONE } from "../config/constants";

const AuthenticatedUserSettingsContext = createContext();
const { Provider } = AuthenticatedUserSettingsContext;

function AuthenticatedUserSettingsProvider({ children }) {
	const { authAxios } = useContext(FetchContext);
	const { authState } = useContext(AuthContext);

	const [isFetchingUserSettings, setIsFetchingUserSettings] = useState(true);
	const [userSettingsData, setUserSettingsData] = useState(null);

	// On application init, and whenever the token changes, re-request user settings
	const [shouldRequest, setShouldRequest] = useState(false);
	useEffect(() => {
		if (authState.token) {
			setShouldRequest(true);
			setIsFetchingUserSettings(true);
		} else {
			setIsFetchingUserSettings(false);
		}
	}, [authState.token]);

	// Fetch user settings data whenver we set the shouldRequest flag to true
	useEffect(() => {
		const getData = async () => {
			try {
				const result = await authAxios({
					method: "get",
					url: getAuthenticatedEndpoint("manage/userSettings")
				});
				setUserSettingsData(result.data);
				setIsFetchingUserSettings(false);
			} catch (err) {
				setUserSettingsData(null);
				setIsFetchingUserSettings(false);
			}
		};

		if (shouldRequest) {
			getData();
			setShouldRequest(false);
		}
	}, [authAxios, shouldRequest]);

	// Transform raw user settings data to include default values
	const transformedUserSettingsData = useMemo(() => {
		// Until we have userSettingsData, return defaults
		if (!userSettingsData) {
			return {
				timeZone: UTC_TIME_ZONE,
				defaultDensity: DENSITY_TYPES.STANDARD,
				defaultUserMetrics: {},
				defaultUserDimensions: {},
				defaultUserKpis: {}
			};
		}

		const {
			defaultNetworkMetrics,
			defaultCampaignMetrics,
			defaultRtbMetrics,
			defaultNetworkDimensions,
			defaultCampaignDimensions,
			defaultRtbDimensions,
			defaultDensity,
			defaultNetworkKpis,
			defaultCampaignKpis,
			defaultRtbKpis,
			timeZone
		} = userSettingsData;

		return {
			defaultUserMetrics: {
				network: !isEmpty(defaultNetworkMetrics)
					? defaultNetworkMetrics
					: METRICS_DEFAULT_VALUES.network,
				campaign: !isEmpty(defaultCampaignMetrics)
					? defaultCampaignMetrics
					: METRICS_DEFAULT_VALUES.campaign,
				rtb: !isEmpty(defaultRtbMetrics)
					? defaultRtbMetrics
					: METRICS_DEFAULT_VALUES.rtb
			},
			defaultUserDimensions: {
				network: defaultNetworkDimensions || [],
				campaign: defaultCampaignDimensions || [],
				rtb: defaultRtbDimensions || []
			},
			defaultUserKpis: {
				network: defaultNetworkKpis,
				campaign: defaultCampaignKpis,
				rtb: defaultRtbKpis
			},
			defaultDensity: defaultDensity || DENSITY_TYPES.STANDARD,
			userTimeZone: timeZone || UTC_TIME_ZONE
		};
	}, [userSettingsData]);

	// Expose the transformed user settings data to the rest of the application
	const {
		defaultUserMetrics,
		defaultUserDimensions,
		defaultUserKpis,
		defaultDensity,
		userTimeZone
	} = transformedUserSettingsData;

	return (
		<Provider
			value={{
				setUserSettingsData,
				isFetchingUserSettings,
				userTimeZone,
				defaultUserMetrics,
				defaultUserDimensions,
				defaultUserKpis,
				defaultDensity
			}}
		>
			{children}
		</Provider>
	);
}

AuthenticatedUserSettingsProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export { AuthenticatedUserSettingsContext, AuthenticatedUserSettingsProvider };
