import React from "react";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import commonLocaleContent from "../commonLocaleContent";
import localeContent from "./localeContent";

const trackerColumnsConfig = [
	{ key: "typeLabel", name: localeContent.COLUMN_TYPE },
	{
		key: "placementEnabled",
		name: localeContent.COLUMN_PLACEMENT_TRACKER,
		cellDataType: "enabled"
	},
	{
		key: "mediaEnabled",
		name: localeContent.COLUMN_MEDIA_TRACKER,
		cellDataType: "enabled"
	},
	{
		key: COLUMN_KEY_STATUS,
		name: commonLocaleContent.COLUMN_HEADER_STATUS,
		cellDataType: "upperCase"
	}
];

function TrackerIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.TRACKER}
			columnsConfig={trackerColumnsConfig}
			resourceEndpoint="manage/trackers"
			resourceRoute="trackers"
			showDataGrid
		/>
	);
}

export default TrackerIndex;
