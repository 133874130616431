import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import {
	Paper,
	Box,
	Grid,
	Button,
	IconButton,
	InputBase,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Divider,
	FormHelperText
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import IconClose from "@mui/icons-material/Close";

import Switch from "../FormFieldSwitch/Switch";
import Radio from "../FormFieldRadio/Radio";
import FileUploadButton from "../FileUploadButton/FileUploadButton";
import SearchTextField from "../SearchTextField/SearchTextField";
import Tooltip from "../Tooltip/Tooltip";
import lc from "./localeContent";
import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";

const removeProtocol = domain => domain.replace(/^https?:\/\//, "");

const isAlreadyInList = (newValue, list) =>
	list?.some(restriction => restriction === newValue.trim());

export const KEY_ENABLED = "enabled";
const KEY_ACCESS_RESTRICTION_TYPE = "accessRestrictionType";
const KEY_RESTRICTIONS = "restrictions";
const allowBlockOptions = [
	{ value: "whitelist", label: lc.ALLOW_LABEL },
	{ value: "blacklist", label: lc.BLOCK_LABEL }
];

const getInheritanceKeys = name => ({
	whitelist: `whitelist-${name}`,
	blacklist: `blacklist-${name}`
});

// Splits file content of the uploaded file by lines and generates an array of strings representing unique lines.
// Uniqueness check is case-insensitive
function uniqueElements(uploadedFileContent) {
	const splitList = uploadedFileContent
		.split(/[\r\n]+/)
		.map(item => item.toLowerCase().trim());
	return [...new Set(splitList)].filter(Boolean);
}

// Used by FieldTypes
export const transformInitData = (data, fieldName) => {
	const fieldData = data[fieldName] || {};
	if ((fieldData[KEY_RESTRICTIONS] || []).length > 0) {
		return { ...fieldData, [KEY_ENABLED]: true };
	}
	const inheritedValues = data.inheritedValues || {};
	const inheritanceKeys = getInheritanceKeys(fieldName);
	if ((inheritedValues[inheritanceKeys.whitelist] || []).length > 0) {
		return {
			...fieldData,
			[KEY_ENABLED]: true,
			[KEY_ACCESS_RESTRICTION_TYPE]: "whitelist"
		};
	}
	if ((inheritedValues[inheritanceKeys.blacklist] || []).length > 0) {
		return {
			...fieldData,
			[KEY_ENABLED]: true,
			[KEY_ACCESS_RESTRICTION_TYPE]: "blacklist"
		};
	}
	return fieldData;
};
export const transformSubmitData = (data, fieldName) => {
	const { [KEY_ENABLED]: enabled, ...submissionData } = data;
	if (!enabled) {
		return {
			[fieldName]: {
				...submissionData,
				[KEY_RESTRICTIONS]: []
			}
		};
	}
	return { [fieldName]: submissionData };
};

const buildInheritedValuesMessage = (inheritedValues, inheritanceKeys) =>
	Object.entries(inheritanceKeys).reduce(
		(aggregator, [key, value]) => ({
			...aggregator,
			[key]: (inheritedValues[value] || []).join(",")
		}),
		{}
	);

function FormFieldListTargeting(props) {
	const {
		name,
		tooltip,
		fieldTypeOptions: { targetingTypeLabel, validatorFunction },
		inheritedValues
	} = props;
	const inheritanceKeys = useMemo(() => getInheritanceKeys(name), [name]);
	const [field, , helpers] = useField(name);
	const {
		[KEY_ACCESS_RESTRICTION_TYPE]: accessRestrictionType,
		[KEY_RESTRICTIONS]: restrictions,
		[KEY_ENABLED]: enabled
	} = field.value;

	const [fileExtensionError, setFileExtensionError] = useState("");
	const [inheritedValuesMessage, setInheritedValuesMessage] = React.useState(
		{}
	);

	// Watch for changes to inheritedValues
	useEffect(() => {
		const newInheritedValues = buildInheritedValuesMessage(
			inheritedValues,
			inheritanceKeys
		);
		setInheritedValuesMessage(newInheritedValues);
	}, [inheritedValues, inheritanceKeys]);

	const [searchFilter, setSearchFilter] = useState("");
	const [newRestriction, setNewRestriction] = useState("");
	const [restrictionError, setRestrictionError] = useState("");

	const updateRestrictionType = React.useCallback(
		value => {
			helpers.setValue({
				...field.value,
				[KEY_ACCESS_RESTRICTION_TYPE]: value
			});
		},
		[helpers, field.value]
	);

	const addRestriction = () => {
		const sanitizedRestriction = removeProtocol(newRestriction.toLowerCase());
		const isValidRestriction =
			!validatorFunction || validatorFunction(sanitizedRestriction);

		if (!isValidRestriction) {
			return setRestrictionError(
				lc.INVALID_ERROR_MESSAGE({ targetingTypeLabel })
			);
		}

		if (isAlreadyInList(sanitizedRestriction, restrictions)) {
			return setRestrictionError(
				lc.DUPPLICATED_ERROR_MESSAGE({ targetingTypeLabel })
			);
		}

		// Don't do anything if the input is empty
		if (sanitizedRestriction.trim()) {
			setRestrictionError("");
			helpers.setValue({
				...field.value,
				[KEY_RESTRICTIONS]: restrictions
					? [sanitizedRestriction, ...restrictions]
					: [sanitizedRestriction]
			});
			setNewRestriction("");
		}
		return undefined;
	};

	const removeRestriction = restriction => {
		helpers.setValue({
			...field.value,
			[KEY_RESTRICTIONS]: restrictions.filter(val => val !== restriction)
		});
	};

	const removeAllRestrictions = () => {
		helpers.setValue({
			...field.value,
			[KEY_RESTRICTIONS]: []
		});
	};

	const uploadFile = file => {
		const extension = file.name
			.split(".")
			.pop()
			.toLowerCase();

		if (extension === "csv") {
			setFileExtensionError("");
			const fileReader = new FileReader();

			fileReader.onload = fileLoadedEvent => {
				const srcData = fileLoadedEvent.target.result;

				const fileRestrictions = uniqueElements(srcData);
				const newRestrictions = fileRestrictions
					.map(restriction => removeProtocol(restriction.toLowerCase()))
					.filter(validatorFunction)
					.filter(restriction => !isAlreadyInList(restriction, restrictions));

				helpers.setValue({
					...field.value,
					[KEY_RESTRICTIONS]: restrictions
						? [...newRestrictions, ...restrictions]
						: newRestrictions
				});
			};
			fileReader.readAsText(file);
		} else {
			setFileExtensionError(lc.FILE_EXTENSION_ERROR_MESSAGE);
		}
	};

	return (
		<Grid item container spacing={MUI_GRID_CONTAINER_SPACING} xs={12}>
			<Grid item xs={12}>
				<Switch
					id="list-targeting-enabled"
					name="listTargetingEnabled"
					label={lc.SWITCH_LABEL({ targetingTypeLabel })}
					value={enabled}
					onChange={event => {
						helpers.setValue({
							...field.value,
							[KEY_ENABLED]: event.target.checked
						});
					}}
				/>
			</Grid>
			{enabled && (
				<Grid item xs={10}>
					<Box display="flex" mb={2}>
						<Tooltip title={tooltip || ""}>
							<Grid item sm={3}>
								<Radio
									id="allow-block-selection"
									ariaLabel={lc.RADIO_BUTTON_ARIA_LABEL}
									name="allowBlockSelection"
									options={allowBlockOptions}
									value={accessRestrictionType}
									onChange={event => updateRestrictionType(event.target.value)}
								/>
							</Grid>
						</Tooltip>
						<Box flexGrow={1} />
						<Grid item sm={3}>
							<FileUploadButton
								name={name}
								label={lc.FILE_UPLOAD_BUTTON_LABEL}
								acceptedFileTypes={[".csv"]}
								onFileUpload={uploadFile}
								fullWidth
							/>
						</Grid>
					</Box>
					{fileExtensionError && (
						<FormHelperText
							sx={{ display: "flex", justifyContent: "right" }}
							error
						>
							{fileExtensionError}
						</FormHelperText>
					)}
					<Paper elevation={3}>
						<Box display="flex" px={2} py={1}>
							<SearchTextField
								id="search-restrictions"
								searchQueryValue={searchFilter}
								onSearchQueryValueChange={setSearchFilter}
								variant="filled"
								size="small"
							/>
							<Box flexGrow={1} />
							<Button onClick={removeAllRestrictions}>
								{lc.REMOVE_ALL_LABEL}
							</Button>
						</Box>
						<Divider />
						<Grid item container>
							<Box
								width={1}
								height={240}
								sx={{
									overflowY: "auto",
									p: 1.5
								}}
							>
								<List dense>
									{restrictions &&
										restrictions
											.filter(
												restriction =>
													searchFilter === "" ||
													restriction.includes(searchFilter)
											)
											.map(restriction => (
												<ListItem key={restriction}>
													<ListItemText primary={restriction} />
													<ListItemSecondaryAction>
														<IconButton
															aria-label={lc.REMOVE_RESTRICTION_BUTTON_LABEL({
																restriction
															})}
															onClick={() => removeRestriction(restriction)}
														>
															<IconClose />
														</IconButton>
													</ListItemSecondaryAction>
												</ListItem>
											))}
								</List>
							</Box>
						</Grid>
						<Divider />
						{inheritedValuesMessage[accessRestrictionType] && (
							<FormHelperText sx={{ pl: 2 }}>
								{inheritedValuesMessage[accessRestrictionType]}
							</FormHelperText>
						)}
						<Box display="flex" px={2} pt={1}>
							<InputBase
								id="new-restriction-input"
								name="newRestrictionInput"
								placeholder={lc.RESTRICTION_INPUT_LABEL({
									targetingTypeLabel
								})}
								fullWidth
								value={newRestriction}
								onChange={event => {
									if (event.target.value === "") {
										setRestrictionError("");
									}
									setNewRestriction(event.target.value);
								}}
								onKeyDown={event => {
									if (event.key === "Enter" && event.target.value !== "") {
										event.preventDefault();
										addRestriction();
									}
								}}
							/>
							<IconButton
								aria-label={lc.ADD_RESTRICTION_BUTTON_LABEL}
								size="small"
								onClick={addRestriction}
							>
								<AddIcon />
							</IconButton>
						</Box>
						{restrictionError && (
							<FormHelperText sx={{ pl: 2, pb: 1 }} error>
								{restrictionError}
							</FormHelperText>
						)}
					</Paper>
				</Grid>
			)}
		</Grid>
	);
}

FormFieldListTargeting.propTypes = {
	name: PropTypes.string.isRequired,
	tooltip: PropTypes.string,
	fieldTypeOptions: PropTypes.shape({
		targetingTypeLabel: PropTypes.string.isRequired,
		validatorFunction: PropTypes.func
	}).isRequired,
	inheritedValues: PropTypes.shape()
};

FormFieldListTargeting.defaultProps = {
	inheritedValues: {},
	tooltip: undefined
};

export default FormFieldListTargeting;
