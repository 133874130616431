import React from "react";
import { format } from "date-fns";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import lc from "./localeContent";

export const DATE_FORMAT = "MM/dd/yyyy";
const roleColumnsConfig = [
	{ key: "status", name: lc.STATUS },
	{ key: "type", name: lc.TYPE },
	{ key: "createdByName", name: lc.CREATED_BY },
	{
		key: "createdAt",
		name: lc.CREATE_DATE,
		formatter: value => format(new Date(value), DATE_FORMAT),
		sortDirection: "desc",
		cellDataType: "date",
		width: 150
	},
	{
		key: "notificationDate",
		name: lc.NOTIFICATION_DATE,
		cellDataType: "date",
		width: 150
	},
	{
		key: "inactiveDate",
		name: lc.INACTIVE_DATE,
		formatter: value => value || lc.NOT_APPLICABLE,
		cellDataType: "date"
	},
	{ key: "numUsers", name: lc.AUDIENCE_SIZE }
];

function NotificationsIndex() {
	return (
		<CrudIndex
			resourceName={lc.PAGE_TITLE}
			columnsConfig={roleColumnsConfig}
			resourceEndpoint="manage/notifications"
			resourceRoute="notifications"
			disableDelete
			disableClone
			defaultSortColumnKey="createdAt"
			overrideDefaultColumns
			showDataGrid
		/>
	);
}

export default NotificationsIndex;
