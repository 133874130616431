import React from "react";
import PropTypes from "prop-types";
import { Container } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PageTitleBar from "../PageTitleBar/PageTitleBar";
import CrudDataTable, {
	getPluralResourceName
} from "../CrudDataTable/CrudDataTable";
import useQueryParam from "../../hooks/useQueryParam";

const getAddEntityText = resourceName => `Add ${resourceName}`;

function CrudIndex(props) {
	const {
		resourceName,
		columnsConfig,
		resourceEndpoint,
		resourceRoute,
		disclaimer,
		resourceNamePlural,
		childResourceConfig,
		parentId,
		disableDelete,
		disableClone,
		primaryColumnConfig,
		disableAdd,
		tableRowActionMenuCustomOptions,
		customStartPathEndPoint,
		overrideDefaultColumns,
		defaultSortColumnKey,
		showDataGrid
	} = props;
	const parentIdValue = useQueryParam("parentId");

	const requestParams = parentId
		? {
				[parentId]: parentIdValue
		  }
		: null;

	const createResourceRoute = `/dashboard/${resourceRoute ||
		resourceEndpoint}/INIT`;

	const barTitle = resourceNamePlural || getPluralResourceName(resourceName);

	return (
		<>
			<PageTitleBar
				barTitle={barTitle}
				actionButtonText={disableAdd ? null : getAddEntityText(resourceName)}
				actionButtonIconComponent={AddIcon}
				actionButtonRoute={createResourceRoute}
			/>
			<Container maxWidth={false}>
				<CrudDataTable
					columnsConfig={columnsConfig}
					resourceName={resourceName}
					resourceNamePlural={resourceNamePlural}
					resourceEndpoint={resourceEndpoint}
					customStartPathEndPoint={customStartPathEndPoint}
					resourceRoute={resourceRoute}
					disclaimer={disclaimer}
					childResourceConfig={childResourceConfig}
					requestParams={requestParams}
					disableDelete={disableDelete}
					disableClone={disableClone}
					primaryColumnConfig={primaryColumnConfig}
					tableRowActionMenuCustomOptions={tableRowActionMenuCustomOptions}
					overrideDefaultColumns={overrideDefaultColumns}
					defaultSortColumnKey={defaultSortColumnKey}
					showDataGrid={showDataGrid}
				/>
			</Container>
		</>
	);
}

CrudIndex.propTypes = {
	resourceName: PropTypes.string.isRequired,
	columnsConfig: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	resourceEndpoint: PropTypes.string.isRequired,
	customStartPathEndPoint: PropTypes.func,
	resourceRoute: PropTypes.string,
	disclaimer: PropTypes.string,
	resourceNamePlural: PropTypes.string,
	childResourceConfig: PropTypes.shape({
		name: PropTypes.string,
		route: PropTypes.string,
		deleteAssociatedResourceWarning: PropTypes.string
	}),
	parentId: PropTypes.string,
	disableDelete: PropTypes.bool,
	disableClone: PropTypes.bool,
	primaryColumnConfig: PropTypes.shape({
		key: PropTypes.string,
		name: PropTypes.string
	}),
	disableAdd: PropTypes.bool,
	tableRowActionMenuCustomOptions: PropTypes.arrayOf(PropTypes.shape()),
	overrideDefaultColumns: PropTypes.bool,
	defaultSortColumnKey: PropTypes.string,
	showDataGrid: PropTypes.bool
};

CrudIndex.defaultProps = {
	disclaimer: null,
	resourceRoute: null,
	resourceNamePlural: null,
	childResourceConfig: null,
	parentId: null,
	disableDelete: false,
	disableClone: false,
	primaryColumnConfig: null,
	disableAdd: false,
	tableRowActionMenuCustomOptions: undefined,
	customStartPathEndPoint: undefined,
	overrideDefaultColumns: false,
	defaultSortColumnKey: "updatedAt",
	showDataGrid: false
};

export default CrudIndex;
