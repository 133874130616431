const METRIC_LIST = {
	rtb: [
		{ id: "rtbOpportunities", default: true },
		{ id: "rtbBids", default: true },
		{ id: "wins", default: true },
		{ id: "impressions", default: true },
		{ id: "bidrate", default: true },
		{ id: "winrate", default: true },
		{ id: "closerate", default: true },
		{ id: "bidprice" },
		{ id: "bidrevenue" },
		{ id: "winprice", default: true },
		{ id: "winrevenue", default: true },
		{ id: "pubcpm" },
		{ id: "pubrevenue" },
		{ id: "margin" },
		{ id: "netRevenue" },
		{ id: "bidErrors" },
		{ id: "tcpErrors" },
		{ id: "timeouts" },
		{ id: "quartile0" },
		{ id: "quartile25" },
		{ id: "quartile50" },
		{ id: "quartile75" },
		{ id: "quartile100", default: true },
		{ id: "moatViewability" },
		{ id: "moatAudibility" },
		{ id: "moatVerifiedCpm" },
		{ id: "moatVerifiedRevenue" },
		{ id: "viewablerate" },
		{ id: "bidRequestCost", default: true },
		{ id: "demandPartnerFee" }
	],
	network: [
		{ id: "opportunities", default: true },
		{ id: "adAttempts" },
		{ id: "impressions", default: true },
		{ id: "fillrate", default: true },
		{ id: "potentialtofill" },
		{ id: "grosscpm", default: true },
		{ id: "grossrevenue", default: true },
		{ id: "pubcpm" },
		{ id: "pubrevenue" },
		{ id: "margin" },
		{ id: "netRevenue" },
		{ id: "quartile0" },
		{ id: "quartile25" },
		{ id: "quartile50" },
		{ id: "quartile75" },
		{ id: "quartile100", default: true },
		{ id: "clicks" },
		{ id: "ctr" },
		{ id: "moatViewability" },
		{ id: "moatAudibility" },
		{ id: "moatVerifiedCpm" },
		{ id: "moatVerifiedRevenue" },
		{ id: "viewablerate" },
		{ id: "opportunityCost", default: true },
		{ id: "opportunityCostPercent" },
		{ id: "demandPartnerFee" }
	],
	campaign: [
		{ id: "demandOpportunities" },
		{ id: "attempts" },
		{ id: "impressions", default: true },
		{ id: "fillrate" },
		{ id: "grosscpm", default: true },
		{ id: "grossrevenue", default: true },
		{ id: "pubcpm" },
		{ id: "pubrevenue" },
		{ id: "margin" },
		{ id: "netRevenue" },
		{ id: "quartile0" },
		{ id: "quartile25" },
		{ id: "quartile50" },
		{ id: "quartile75" },
		{ id: "quartile100", default: true },
		{ id: "clicks" },
		{ id: "ctr" },
		{ id: "moatViewability" },
		{ id: "moatAudibility" },
		{ id: "moatVerifiedCpm" },
		{ id: "moatVerifiedRevenue" },
		{ id: "viewablerate" },
		{ id: "demandPartnerFee" }
	]
};

const getDefaultMetricsIdsByReportType = reportType =>
	METRIC_LIST[reportType]
		.filter(metric => metric.default === true)
		.map(({ id }) => id);

const METRICS_DEFAULT_VALUES = {
	network: getDefaultMetricsIdsByReportType("network"),
	campaign: getDefaultMetricsIdsByReportType("campaign"),
	rtb: getDefaultMetricsIdsByReportType("rtb")
};

export { METRIC_LIST, METRICS_DEFAULT_VALUES };
