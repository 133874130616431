import React from "react";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import commonLocaleContent from "../commonLocaleContent";

const campaignColumnsConfig = [
	{ key: "insertionOrderName", name: commonLocaleContent.INSERTION_ORDER },
	{ key: "advName", name: commonLocaleContent.ADVERTISER },
	{
		key: COLUMN_KEY_STATUS,
		name: commonLocaleContent.COLUMN_HEADER_STATUS,
		cellDataType: "upperCase"
	},
	{
		key: "startDate",
		name: commonLocaleContent.COLUMN_HEADER_START_DATE,
		cellDataType: "date"
	},
	{
		key: "endDate",
		name: commonLocaleContent.COLUMN_HEADER_END_DATE,
		cellDataType: "date"
	},
	{
		key: "cpm",
		name: commonLocaleContent.COLUMN_HEADER_CPM,
		cellDataType: "currency"
	},
	{
		key: "impressions",
		name: commonLocaleContent.COLUMN_HEADER_IMPRESSIONS,
		cellDataType: "number"
	},
	{
		key: "grossRevenue",
		name: commonLocaleContent.COLUMN_HEADER_GROSS_REVENUE,
		cellDataType: "currency"
	}
];

const campaignChildResourceConfig = {
	name: "Media",
	pluralName: "Media",
	route: "media",
	deleteAssociatedResourceWarning: "media"
};

function CampaignIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.CAMPAIGN}
			columnsConfig={campaignColumnsConfig}
			resourceEndpoint="manage/campaigns"
			resourceRoute="campaigns"
			parentId="insertionOrderId"
			disclaimer={commonLocaleContent.DEMAND_INDEX_SCREEN_DISCLAIMER_CAPTION}
			childResourceConfig={campaignChildResourceConfig}
			showDataGrid
		/>
	);
}

export default CampaignIndex;
