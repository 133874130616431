import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import RouterLink from "../Routing/LinkWithQuery";

const darkButtonSx = {
	color: "common.white",
	bgcolor: "secondary.800",
	"&:hover": {
		backgroundColor: "secondary.700"
	},
	margin: "auto"
};

function ButtonContainedDark(props) {
	const {
		label,
		linkToRoute,
		size,
		StartIconComponent,
		onClick,
		target,
		download,
		isExternal
	} = props;

	return isExternal ? (
		<Button
			sx={darkButtonSx}
			href={linkToRoute}
			component="a"
			onClick={onClick}
			size={size}
			variant="contained"
			type="button"
			startIcon={StartIconComponent ? <StartIconComponent /> : undefined}
			target={target}
			download={download}
		>
			{label}
		</Button>
	) : (
		<Button
			sx={darkButtonSx}
			to={linkToRoute}
			component={linkToRoute ? RouterLink : undefined}
			onClick={onClick}
			size={size}
			variant="contained"
			type="button"
			startIcon={StartIconComponent ? <StartIconComponent /> : undefined}
			target={target}
			download={download}
		>
			{label}
		</Button>
	);
}

ButtonContainedDark.propTypes = {
	label: PropTypes.string.isRequired,
	linkToRoute: PropTypes.string,
	onClick: PropTypes.func,
	size: PropTypes.string,
	StartIconComponent: PropTypes.shape(),
	target: PropTypes.string,
	download: PropTypes.string,
	isExternal: PropTypes.bool
};

ButtonContainedDark.defaultProps = {
	linkToRoute: undefined,
	onClick: undefined,
	size: undefined,
	StartIconComponent: undefined,
	target: undefined,
	download: undefined,
	isExternal: false
};

export default ButtonContainedDark;
