import React from "react";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import commonLocaleContent from "../commonLocaleContent";

const mediaColumnsConfig = [
	{ key: "campaignName", name: commonLocaleContent.CAMPAIGN },
	{ key: "insertionOrderName", name: commonLocaleContent.INSERTION_ORDER },
	{ key: "advName", name: commonLocaleContent.ADVERTISER },
	{
		key: COLUMN_KEY_STATUS,
		name: commonLocaleContent.COLUMN_HEADER_STATUS,
		cellDataType: "upperCase"
	},
	{
		key: "cpm",
		name: commonLocaleContent.COLUMN_HEADER_CPM,
		cellDataType: "currency"
	},
	{
		key: "impressions",
		name: commonLocaleContent.COLUMN_HEADER_IMPRESSIONS,
		cellDataType: "number"
	},
	{
		key: "grossRevenue",
		name: commonLocaleContent.COLUMN_HEADER_GROSS_REVENUE,
		cellDataType: "currency"
	}
];

function MediaIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.MEDIA}
			columnsConfig={mediaColumnsConfig}
			resourceEndpoint="manage/media"
			resourceRoute="media"
			parentId="campaignId"
			disclaimer={commonLocaleContent.DEMAND_INDEX_SCREEN_DISCLAIMER_CAPTION}
			resourceNamePlural={commonLocaleContent.MEDIA}
			showDataGrid
		/>
	);
}

export default MediaIndex;
