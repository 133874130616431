import React from "react";
import PropTypes from "prop-types";
import CrudDataTable from "../CrudDataTable/CrudDataTable";

function ChildResourcePanel(props) {
	const { childResourceConfig, resourceId } = props;
	const {
		resourceName,
		columnsConfig,
		resourceEndpoint,
		resourceRoute,
		disclaimer,
		getRequestParams
	} = childResourceConfig;

	const requestParams = resourceId ? getRequestParams(resourceId) : null;

	const addChildButtonConfig = {
		label: `Add ${resourceName}`,
		to: `/dashboard/${resourceRoute ||
			resourceEndpoint}/INIT?parentId=${resourceId}`
	};
	return (
		<CrudDataTable
			columnsConfig={columnsConfig}
			resourceName={resourceName}
			resourceEndpoint={resourceEndpoint}
			resourceRoute={resourceRoute}
			disclaimer={disclaimer}
			requestParams={requestParams}
			noDataActionButtonConfig={addChildButtonConfig}
			tableActionButtonConfig={addChildButtonConfig}
		/>
	);
}
ChildResourcePanel.propTypes = {
	childResourceConfig: PropTypes.shape().isRequired,
	resourceId: PropTypes.string
};
ChildResourcePanel.defaultProps = {
	resourceId: null
};

export default ChildResourcePanel;
