import React from "react";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import { BASE_ROUTES } from "../../config/baseRoutesConfig";
import commonLocaleContent from "../commonLocaleContent";
import localeContent from "./localeContent";

const bidderColumnsConfig = [
	{ key: "cookieSyncUrl", name: localeContent.COLUMN_COOKIE_SYNC_URL },
	{ key: "rtbSpecVersion", name: localeContent.COLUMN_RTB_VERSION },
	{
		key: COLUMN_KEY_STATUS,
		name: commonLocaleContent.COLUMN_HEADER_STATUS,
		cellDataType: "upperCase"
	}
];

function BidderIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.BIDDER}
			columnsConfig={bidderColumnsConfig}
			resourceEndpoint="manage/rtb-bidders"
			resourceRoute={BASE_ROUTES.bidders}
			showDataGrid
		/>
	);
}

export default BidderIndex;
