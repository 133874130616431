import React from "react";
import * as Yup from "yup";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import CrudForm from "../../components/CrudForm/CrudForm";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import PLACEMENT_ALIGNMENT_COLUMNS from "./demandAlignmentColumns";
import AlignmentTool from "../../components/AlignmentTool/AlignmentTool";
import validators from "../../utils/validators";
import mapEnumToFieldOptions from "../../utils/mapEnumToOptions";
import {
	BREADCRUMBS_ENUM,
	SERVER_URL_WITH_VERSION
} from "../../config/constants";
import FormFieldButton from "../../components/FormFieldButton/FormFieldButton";
import PLACEMENT_TYPE_ENUM from "../../enum/placementType";

const mapAlignmentOptions = (optionsData = {}) => {
	const media = optionsData?.media || [];
	const programmaticDemands = optionsData?.programmaticDemands || [];
	return [
		...media.map(demand => ({
			...demand,
			cpmFloor: demand.cpm,
			demandType: localeContent.MANAGED_DEMAND_TYPE_LABEL
		})),
		...programmaticDemands.map(demand => ({
			...demand,
			isProgrammaticDemand: true,
			cpmFloor: demand.floor,
			demandType: localeContent.PROGRAMMATIC_DEMAND_TYPE_LABEL
		}))
	];
};

const PLACEMENT_TYPE_OPTIONS = mapEnumToFieldOptions(PLACEMENT_TYPE_ENUM);
const breadcrumbsConfig = [
	BREADCRUMBS_ENUM.PUBLISHER,
	BREADCRUMBS_ENUM.SITES,
	BREADCRUMBS_ENUM.NAME
];

const TABS_CONFIG = [
	{
		key: "SETTINGS",
		label: commonLocaleContent.TAB_LABEL_SETTINGS,
		fieldsConfig: [
			{
				// Section For Tag based placements that call Delivery directly
				key: "TAG_INFO",
				hideOnCreate: true,
				renderIf: formValues => !formValues.requestTypeRTB,
				fields: [
					{
						name: "secureTag",
						label: localeContent.SECURE_TAG_LABEL,
						readOnly: true,
						fieldTypeOptions: {
							multiline: true
						}
					},
					{
						name: "downloadTags",
						fieldTypeOptions: {
							buttonLabel: localeContent.DOWNLOAD_PLACEMENT_TAGS_LABEL,
							getLinkToRoute: crudFormResourceId =>
								`${SERVER_URL_WITH_VERSION}/manage/placements/${crudFormResourceId}/tag_file`
						},
						fieldType: FieldTypes.NON_FIELD_COMPONENT,
						component: FormFieldButton,
						gridConfig: { md: 6 },
						hideOnCreate: true
					}
				]
			},
			{
				// Section For Bidder Service based placements that call Delivery via the Bidder Service
				key: "BIDDER_INFO",
				hideOnCreate: true,
				renderIf: formValues => formValues.requestTypeRTB,
				fields: [
					{
						name: "guid",
						label: localeContent.GUID_LABEL,
						tooltip: localeContent.GUID_TOOLTIP,
						gridConfig: { lg: 4, md: 6, sm: 8 },
						readOnly: true
					}
				]
			},
			{
				key: "PUBLISHER_SETTINGS",
				hideOnEdit: true,
				fields: [
					{
						name: "siteId",
						label: localeContent.SITE_ID_LABEL,
						tooltip: localeContent.SITE_ID_TOOLTIP,
						isRequired: true,
						disableOnEdit: true,
						gridConfig: { md: 8 },
						fieldType: FieldTypes.COMBO_BOX,
						optionsConfig: {
							requestEndpoint: "manage/search/sites"
						}
					}
				]
			},
			{
				key: "BASIC_SETTINGS",
				renderIf: formValues => formValues.siteId,
				fields: [
					{
						name: "name",
						label: localeContent.PLACEMENT_NAME_LABEL,
						isRequired: true,
						gridConfig: { md: 8 }
					},
					{
						name: "status",
						label: localeContent.STATUS_LABEL,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: [
								{
									value: "active",
									label: localeContent.STATUS_OPTION_LABEL_ACTIVE
								},
								{
									value: "inactive",
									label: localeContent.STATUS_OPTION_LABEL_INACTIVE
								}
							]
						},
						defaultValue: "active"
					},
					{
						name: "revenueType",
						label: localeContent.REVENUE_TYPE_LABEL,
						gridConfig: { md: 5 },
						tooltip: localeContent.REVENUE_TYPE_TOOLTIP,
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: [
								{
									value: "flat_rate",
									label: "Flat Rate ($)"
								},
								{
									value: "rev_share",
									label: "Revenue Share (%)"
								}
							]
						},
						defaultValue: "flat_rate"
					},
					{
						name: "floor",
						isRequired: true,
						label: localeContent.FLOOR_LABEL,
						fieldType: FieldTypes.CURRENCY,
						gridConfig: { md: 3, lg: 2 },
						tooltip: localeContent.FLOOR_TOOLTIP
					},
					{
						name: "revenueShare",
						renderIf: formValues => formValues.revenueType === "rev_share",
						label: localeContent.REVENUE_SHARE_LABEL,
						fieldType: FieldTypes.PERCENT,
						gridConfig: { md: 3, lg: 2 },
						tooltip: localeContent.DEFAULT_REVENUE_SHARE_TOOLTIP
					},
					{
						name: "bidderMargin",
						permissionRequired: "VIEW_BIDDER_MARGIN_FIELD",
						label: localeContent.BIDDER_MARGIN_LABEL,
						fieldType: FieldTypes.PERCENT,
						gridConfig: { md: 3, lg: 2 },
						tooltip: localeContent.BIDDER_MARGIN_TOOLTIP,
						validationSchema: Yup.number()
							.min(0, commonLocaleContent.PERCENTAGE_FIELD_MUST_BE_POSITIVE)
							.max(
								100,
								commonLocaleContent.PERCENTAGE_FIELD_MUST_BE_BETWEEN_0_100
							)
							.required(commonLocaleContent.REQUIRED_FIELD_WARNING)
					},
					{
						name: "cloneAlignments",
						label: localeContent.CLONE_ALIGNMENTS.LABEL,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: true,
						tooltip: localeContent.CLONE_ALIGNMENTS.TOOLTIP,
						renderIf: (formValues, initData, resourceId, isClonedResource) =>
							isClonedResource
					}
				]
			},
			{
				key: "PROGRAMMATIC_SETTINGS",
				renderIf: formValues => formValues.siteId,
				fields: [
					{
						name: "auctionFloorIncrement",
						label: localeContent.AUCTION_FLOOR_INCREMENT_LABEL,
						fieldType: FieldTypes.CURRENCY,
						gridConfig: { md: 3, lg: 3 },
						tooltip: localeContent.AUCTION_FLOOR_INCREMENT_TOOLTIP
					},
					{
						name: "auctionType",
						label: localeContent.AUCTION_TYPE_LABEL,
						tooltip: localeContent.AUCTION_TYPE_TOOLTIP,
						gridConfig: { sm: 7, md: 3 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: [
								{ value: "FIRST_PRICE", label: "First Price" },
								{ value: "SECOND_PRICE", label: "Second Price" }
							]
						},
						defaultValue: "SECOND_PRICE"
					},
					{
						name: "allowMultipleWinners",
						renderIf: formValues =>
							["vast", "mobile"].some(value => value === formValues.type) &&
							!formValues.usingMultipleImpObjects,
						permissionRequired: "VIEW_MULTIPLE_WINNERS_FIELD",
						label: localeContent.ALLOW_MULTIPLE_WINNERS_LABEL,
						tooltip: localeContent.ALLOW_MULTIPLE_WINNERS_LABEL,
						gridConfig: { sm: 6, md: 2 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: [
								{ value: false, label: localeContent.SELECT_OPTION_LABEL_NO },
								{ value: true, label: localeContent.SELECT_OPTION_LABEL_YES }
							]
						},
						defaultValue: false
					},
					{
						name: "usingMultipleImpObjects",
						label: localeContent.FIELD_MULTIPLE_IMP_OBJECTS_LABEL,
						gridConfig: { md: 2 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false,
						tooltip: localeContent.FIELD_MULTIPLE_IMP_OBJECTS_TOOLTIP,
						permissionRequired: "VIEW_MULTIPLE_IMP_OBJECTS_FIELDS"
					},
					{
						name: "allowAuctionWinByCPMperSecond",
						renderIf: (formValues, initData) =>
							["vast", "mobile", "outstream"].some(
								value => value === formValues.type
							) &&
							formValues.auctionType === "FIRST_PRICE" &&
							initData.allowServerSideRequests,
						permissionRequired: "CAN_VIEW_CPM_PER_SEC",
						label: localeContent.ALLOW_AUCTION_WIN_BY_CPM_PER_SEC,
						tooltip: localeContent.ALLOW_AUCTION_WIN_BY_CPM_PER_SEC_TOOLTIP,
						gridConfig: { sm: 6, md: 2 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false
					}
				]
			},
			{
				key: "PLAYER_SETTINGS",
				renderIf: formValues => formValues.siteId,
				fields: [
					{
						name: "type",
						label: localeContent.TYPE_LABEL,
						tooltip: localeContent.TYPE_TOOLTIP,
						gridConfig: { md: 3 },
						disableOnEdit: true,
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: PLACEMENT_TYPE_OPTIONS
						},
						defaultValue: "mobile"
					},
					{
						name: "vastVersion",
						renderIf: formValues =>
							["vast", "mobile"].some(value => value === formValues.type),
						label: localeContent.VAST_VERSION_LABEL,
						gridConfig: { md: 2 },
						tooltip: localeContent.VAST_VERSION_TOOLTIP,
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: [
								{ value: "2.0", label: "2.0" },
								{ value: "3.0", label: "3.0" },
								{ value: "4.0", label: "4.0" }
							]
						},
						defaultValue: "4.0"
					},
					{
						name: "enableVPAID",
						renderIf: formValues => formValues.type === "vast",
						label: localeContent.ENABLE_VPAID_LABEL,
						gridConfig: { md: 2 },
						tooltip: localeContent.ENABLE_VPAID_TOOLTIP,
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{
									value: true,
									label: localeContent.SELECT_OPTION_LABEL_YES
								},
								{
									value: false,
									label: localeContent.SELECT_OPTION_LABEL_NO
								}
							]
						},
						defaultValue: true
					},
					{
						name: "enableC6AdManager",
						renderIf: formValues =>
							formValues.type === "vast" && formValues.enableVPAID,
						label: localeContent.ENABLE_C6_AD_MANAGER_LABEL,
						gridConfig: { md: 2 },
						tooltip: localeContent.ENABLE_C6_AD_MANAGER_TOOLTIP,
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{
									value: true,
									label: localeContent.SELECT_OPTION_LABEL_YES
								},
								{
									value: false,
									label: localeContent.SELECT_OPTION_LABEL_NO
								}
							]
						},
						defaultValue: true
					},
					{
						name: "size",
						renderIf: formValues => formValues.type === "display",
						isRequiredIfRendered: true,
						validationSchema: Yup.string().when("type", {
							is: type => type === "display",
							then: Yup.string().required(
								`Required when ${localeContent.TYPE_LABEL} is display`
							)
						}),
						label: localeContent.SIZE_LABEL,
						tooltip: localeContent.SIZE_TOOLTIP,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.COMBO_BOX,
						optionsConfig: {
							requestEndpoint: "manage/catalogue/display-sizes",
							mapOptions: option => ({ id: option, name: option }),
							sortOptionsByName: false
						}
					},
					{
						name: "defaultIntercept",
						renderIf: formValues => formValues.type === "outstream",
						label: localeContent.DEFAULT_INTERCEPT_LABEL,
						tooltip: localeContent.DEFAULT_INTERCEPT_TOOLTIP,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: [
								{ label: "Full Page", value: "1001" },
								{ label: "Full-Page +", value: "7001" },
								{ label: "In-Page", value: "3001" },
								{ label: "In-Page +", value: "6001" },
								{ label: "In-View - Floor-unit", value: "4001" },
								{ label: "Slider", value: "8001" }
							]
						},
						defaultValue: "1001"
					},
					{
						name: "mobileIntercept",
						renderIf: formValues => formValues.type === "outstream",
						label: localeContent.MOBILE_INTERCEPT_LABEL,
						tooltip: localeContent.MOBILE_INTERCEPT_TOOLTIP,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: [
								{ label: "Dock to Top", value: "10001" },
								{ label: "Dock to Bottom", value: "12001" },
								{ label: "Non-Docking", value: "11001" }
							]
						},
						defaultValue: "10001"
					},
					{
						name: "dockPosition",
						renderIf: formValues =>
							formValues.type === "outstream" &&
							["6001", "7001", "8001"].some(
								value => value === formValues.defaultIntercept
							),
						label: localeContent.DOCK_POSITION_LABEL,
						tooltip: localeContent.DOCK_POSITION_TOOLTIP,
						gridConfig: { md: 3, offset: { md: 1 } },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: [
								{ label: "Bottom Right", value: "bottom_right" },
								{ label: "Top Right", value: "top_right" },
								{ label: "Bottom Left", value: "bottom_left" },
								{ label: "Top Left", value: "top_left" }
							]
						},
						defaultValue: "bottom_right"
					},
					{
						name: "closeButton",
						renderIf: formValues =>
							formValues.type === "outstream" &&
							["3001", "6001", "7001", "8001"].some(
								value => value === formValues.defaultIntercept
							),
						label: localeContent.CLOSE_BUTTON_LABEL,
						tooltip: localeContent.CLOSE_BUTTON_TOOLTIP,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: true
					},
					{
						name: "requestTypeRTB",
						renderIf: (formValues, initData) =>
							initData.bidderServiceEnabled && formValues.type === "mobile",
						label: localeContent.REQUEST_TYPE_RTB.LABEL,
						tooltip: localeContent.REQUEST_TYPE_RTB.TOOLTIP,
						gridConfig: { md: 2 },
						disableOnEdit: true,
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{
									value: false,
									label: localeContent.REQUEST_TYPE_RTB.OPTIONS.TAG
								},
								{
									value: true,
									label: localeContent.REQUEST_TYPE_RTB.OPTIONS.RTB
								}
							]
						},
						defaultValue: false
					},
					{
						name: "preventDuplicateCreatives",
						renderIf: formValues =>
							formValues.type === "mobile" ||
							(formValues.type === "vast" && !formValues.enableC6AdManager),
						label: localeContent.PREVENT_DUPLICATE_CREATIVES,
						gridConfig: { md: 3 },
						tooltip: localeContent.PREVENT_DUPLICATE_CREATIVES_TOOLTIP,
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{
									value: true,
									label: localeContent.SELECT_OPTION_LABEL_YES
								},
								{
									value: false,
									label: localeContent.SELECT_OPTION_LABEL_NO
								}
							]
						},
						defaultValue: false
					},
					{
						name: "allowInventoryPartnerDomain",
						renderIf: formValues =>
							formValues.type === "mobile" ||
							(formValues.type === "vast" && !formValues.enableC6AdManager),
						label: localeContent.ALLOW_INVENTORY_PARTNER_DOMAIN,
						tooltip: localeContent.ALLOW_INVENTORY_PARTNER_DOMAIN_TOOLTIP,
						gridConfig: { sm: 2, md: 2 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false
					}
				]
			},
			{
				key: "IN_PAGE_CONFIGURATIONS",
				renderIf: formValues =>
					formValues.siteId &&
					formValues.type === "outstream" &&
					["3001", "6001"].some(value => value === formValues.defaultIntercept),
				fields: [
					{
						name: "cssPath",

						label: localeContent.CSS_PATH_LABEL,
						tooltip: localeContent.CSS_PATH_TOOLTIP,
						gridConfig: { md: 6 }
					},
					{
						name: "beforeCssPath",
						label: localeContent.BEFORE_CSS_PATH_LABEL,
						tooltip: localeContent.BEFORE_CSS_PATH_TOOLTIP,
						gridConfig: { md: 6 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{ value: false, label: "Insert Player Below Path" },
								{ value: true, label: "Insert Player Above Path" }
							]
						},
						defaultValue: false
					},
					{
						name: "imageLocation",
						label: localeContent.IMAGE_LOCATION_LABEL,
						tooltip: localeContent.IMAGE_LOCATION_TOOLTIP,
						gridConfig: { md: 6 }
					},
					{
						name: "collapse",
						label: localeContent.COLLAPSE_IN_PAGE_LABEL,
						tooltip: localeContent.COLLAPSE_IN_PAGE_TOOLTIP,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: true
					}
				]
			},
			{
				key: "PLAYBACK_CONFIGURATIONS",
				renderIf: formValues =>
					formValues.siteId && formValues.type === "outstream",
				fields: [
					{
						name: "enableAudio",
						label: localeContent.ENABLE_AUDIO_LABEL,
						tooltip: localeContent.ENABLE_AUDIO_TOOLTIP,
						gridConfig: { md: 2 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false
					},
					{
						name: "avOptOut",
						renderIf: formValues => formValues.enableAudio,
						label: localeContent.AV_OPT_OUT_LABEL,
						tooltip: localeContent.AV_OPT_OUT_TOOLTIP,
						gridConfig: { md: 6 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{ value: false, label: "Audio on Hover" },
								{ value: true, label: "Audio on Click" }
							]
						},
						defaultValue: false
					}
				]
			},
			{
				key: "FILTER_CONFIGURATIONS",
				renderIf: formValues =>
					formValues.siteId && formValues.type === "outstream",
				fields: [
					{
						name: "frequencyCap",
						gridConfig: { custom: true },
						fieldType: FieldTypes.FREQUENCY_CAP,
						defaultValue: -1
					},
					{
						name: "browserBlacklist",
						label: localeContent.BROWSER_BLACKLIST_LABEL,
						tooltip: localeContent.BROWSER_BLACKLIST_TOOLTIP,
						gridConfig: { md: 6, lg: 4 },
						fieldType: FieldTypes.TAGS,
						fieldTypeOptions: {
							fixedOptions: ["IE7", "IE8", "IE9"]
						},
						defaultValue: ["IE7", "IE8", "IE9"]
					},
					{
						name: "elementsBlacklist",
						label: localeContent.ELEMENTS_BLACKLIST_LABEL,
						tooltip: localeContent.ELEMENTS_BLACKLIST_TOOLTIP,
						gridConfig: { md: 6, lg: 4 },
						fieldType: FieldTypes.TAGS,
						defaultValue: []
					}
				]
			},
			{
				key: "PLACEMENT_CONFIGURATIONS",
				renderIf: formValues =>
					formValues.siteId && formValues.type === "outstream",
				fields: [
					{
						name: "framebuster",
						label: localeContent.FRAMEBUSTER_LABEL,
						tooltip: localeContent.FRAMEBUSTER_TOOLTIP,
						gridConfig: { md: 6 }
					},
					{
						name: "viewUrl",
						label: localeContent.VIEW_URL_LABEL,
						tooltip: localeContent.VIEW_URL_TOOLTIP,
						gridConfig: { md: 6 }
					},
					{
						name: "clickUrl",
						label: localeContent.CLICK_URL_LABEL,
						tooltip: localeContent.CLICK_URL_TOOLTIP,
						gridConfig: { md: 6 }
					},
					{
						name: "outstreamPassbackUrl",
						label: localeContent.OUTSTREAM_PASSBACK_URL_LABEL,
						tooltip: localeContent.OUTSTREAM_PASSBACK_URL_TOOLTIP,
						gridConfig: { md: 6 }
					}
				]
			}
		]
	},
	{
		label: commonLocaleContent.TAB_LABEL_QUALITY,
		key: "QUALITY",
		disableIf: formValues => !formValues.siteId,
		fieldsConfig: [
			{
				key: "GENERAL_QUALITY_SETTINGS",
				fields: [
					{
						name: "trackers",
						label: localeContent.TRACKERS_LABEL,
						tooltip: localeContent.TRACKERS_TOOLTIP,
						gridConfig: { md: 8, lg: 8 },
						fieldType: FieldTypes.MULTISELECT,
						permissionRequired: "VIEW_PLACEMENT_TRACKER",
						optionsConfig: {
							requestEndpoint: "manage/trackers",
							getRequestParams: formValues => {
								const requestParams = {
									"statusList[]": "active",
									placementTrackers: true
								};
								const vpaidElibibleType = ["vast", "mobile"].some(
									value => value === formValues.type
								);
								if (vpaidElibibleType && !formValues.enableVPAID) {
									requestParams.noJS = true;
									requestParams.impressionOnly = true;
								}
								return requestParams;
							}
						}
					},
					{
						name: "enableIVTFiltering",
						renderIf: (formValues, initData) =>
							formValues.type === "mobile" && initData.allowIVTFiltering,
						label: localeContent.IVT_FILTERING,
						tooltip: localeContent.IVT_FILTERING_TOOLTIP,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{
									value: true,
									label: localeContent.SELECT_OPTION_LABEL_TRUE
								},
								{
									value: false,
									label: localeContent.SELECT_OPTION_LABEL_FALSE
								}
							]
						},
						defaultValue: true
					},
					{
						name: "maxDuration",
						label: localeContent.MAX_DURATION_LABEL,
						tooltip: localeContent.MAX_DURATION_TOOLTIP,
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.NUMBER,
						fieldTypeOptions: {
							min: 1
						},
						defaultValue: 60,
						validationSchema: Yup.number()
							.integer(localeContent.INTEGER_WARNING)
							.positive(localeContent.GREATER_THAN_ZERO_WARNING)
					},
					{
						name: "instreamSkipTime",
						label: localeContent.INSTREAM_SKIP_TIME_LABEL,
						tooltip: localeContent.INSTREAM_SKIP_TIME_TOOLTIP,
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.NUMBER,
						fieldTypeOptions: {
							min: 1,
							max: 45
						},
						validationSchema: Yup.number()
							.integer(localeContent.INTEGER_WARNING)
							.min(1, localeContent.INSTREAM_SKIP_TIME_RANGE_WARNING)
							.max(45, localeContent.INSTREAM_SKIP_TIME_RANGE_WARNING)
					},
					{
						name: "enableAdResponseMediaLimit",
						label: localeContent.ENABLE_AD_RESPONSE_MEDIA_LIMIT_LABEL,
						tooltip: localeContent.ENABLE_AD_RESPONSE_MEDIA_LIMIT_TOOLTIP,
						gridConfig: { md: 4, lg: 3, offset: { md: 4 } },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false
					},
					{
						name: "adResponseMediaLimit",
						renderIf: formValues => formValues.enableAdResponseMediaLimit,
						isRequiredIfRendered: true,
						validationSchema: Yup.number()
							.when("enableAdResponseMediaLimit", {
								is: true,
								then: Yup.number().required(
									`Required when ${localeContent.ENABLE_AD_RESPONSE_MEDIA_LIMIT_LABEL} is enabled`
								)
							})
							.integer(localeContent.INTEGER_WARNING)
							.positive(localeContent.GREATER_THAN_ZERO_WARNING),
						label: localeContent.AD_RESPONSE_MEDIA_LIMIT_LABEL,
						tooltip: localeContent.AD_RESPONSE_MEDIA_LIMIT_TOOLTIP,
						gridConfig: { md: 4, lg: 3 },
						fieldType: FieldTypes.NUMBER,
						fieldTypeOptions: {
							min: 1
						}
					},
					{
						name: "minBitrate",
						label: localeContent.MIN_BITRATE_LABEL,
						tooltip: localeContent.MIN_BITRATE_TOOLTIP,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.NUMBER,
						isRequired: true
					},
					{
						name: "maxBitrate",
						label: localeContent.MAX_BITRATE_LABEL,
						tooltip: localeContent.MAX_BITRATE_TOOLTIP,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.NUMBER,
						isRequired: true
					}
				]
			},
			{
				key: "LIST_TARGETING_SETTINGS",
				fields: [
					{
						name: "blacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel: localeContent.TARGETING_TYPE_DOMAIN_LABEL,
							validatorFunction: validators.validateDomain
						},
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					},
					{
						name: "advertiserDomainBlacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel:
								localeContent.TARGETING_TYPE_ADVERTISER_DOMAIN_LABEL,
							validatorFunction: validators.validateDomain
						},
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					},
					{
						name: "appNameBlacklistWhitelist",
						tooltip: localeContent.LIST_TARGETING_RADIO_BUTTON_TOOLTIP,
						fieldTypeOptions: {
							targetingTypeLabel: localeContent.TARGETING_TYPE_APP_NAME
						},
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM,
						renderIf: formValues => formValues.type === "mobile"
					},
					{
						name: "appBundleIdBlacklistWhitelist",
						tooltip: localeContent.LIST_TARGETING_RADIO_BUTTON_TOOLTIP,
						fieldTypeOptions: {
							targetingTypeLabel: localeContent.TARGETING_TYPE_APP_BUNDLE_ID,
							validatorFunction: validators.validateUTF8
						},
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM,
						renderIf: formValues => formValues.type === "mobile"
					}
				]
			}
		]
	},
	{
		label: commonLocaleContent.TAB_LABEL_ALIGNMENTS,
		key: "ALIGNMENTS",
		disableOnCreate: true,
		useContainer: false,
		componentConfig: {
			Component: AlignmentTool,
			props: {
				columns: PLACEMENT_ALIGNMENT_COLUMNS,
				tableOptionLabelAvailable: "Available Demand",
				tableOptionLabelAligned: "Aligned Demand",
				availableOptionsEndpoint: "manage/alignment-options/demand",
				availableOptionsIdKey: "placementId",
				alignmentEndpoint: "placements",
				defaultShownColumns: ["name", "cpmFloor", "margin"],
				mapAvailableOptions: mapAlignmentOptions,
				entityTypeLabel: commonLocaleContent.PLACEMENT,
				mapAlignedOptions: mapAlignmentOptions,
				onSave: alignmentObjects => {
					const media = alignmentObjects.filter(
						alignmentObject => !alignmentObject.isProgrammaticDemand
					);
					const programmaticDemands = alignmentObjects.filter(
						alignmentObject => alignmentObject.isProgrammaticDemand
					);
					return {
						media,
						programmaticDemands,
						allowRon: false
					};
				}
			}
		}
	}
];

const sanitizeRequestType = formValues => {
	const allowedTypes = [PLACEMENT_TYPE_ENUM.MOBILE.value];
	if (!allowedTypes.includes(formValues.type)) {
		return { ...formValues, requestTypeRTB: false };
	}
	return formValues;
};

function PlacementsResourceScreen() {
	return (
		<CrudForm
			resourceEndpoint="manage/placements"
			resourceRoute="placements"
			parentIdField="siteId"
			parentStatusKey="siteStatus"
			resourceString={commonLocaleContent.PLACEMENT}
			tabsConfig={TABS_CONFIG}
			breadcrumbsConfig={breadcrumbsConfig}
			preSubmit={formValues => {
				let submissionValues = {
					...formValues,
					allowRon: false,
					locked: false
				};
				submissionValues = sanitizeRequestType(submissionValues);
				return submissionValues;
			}}
			cloneUrlWithId
		/>
	);
}

export default PlacementsResourceScreen;
