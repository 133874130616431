import React from "react";
import CrudForm from "../../components/CrudForm/CrudForm";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import placementLocaleContent from "../Placement/localeContent";
import { BREADCRUMBS_ENUM } from "../../config/constants";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";

const breadcrumbsConfig = [BREADCRUMBS_ENUM.PUBLISHER, BREADCRUMBS_ENUM.NAME];

const TABS_CONFIG = [
	{
		label: localeContent.TAB_LABEL_SETTINGS,
		key: "SETTINGS",
		fieldsConfig: [
			{
				key: "PUBLISHER_SETTINGS",
				hideOnEdit: true,
				fields: [
					{
						label: localeContent.PUBLISHER_ID_LABEL,
						tooltip: localeContent.PUBLISHER_ID_TOOLTIP,
						name: "publisherId",
						isRequired: true,
						disableOnEdit: true,
						gridConfig: { md: 8 },
						fieldType: FieldTypes.COMBO_BOX,
						optionsConfig: {
							requestEndpoint: "manage/search/publishers"
						}
					}
				]
			},
			{
				key: "BASIC_SETTINGS",
				renderIf: formValues => formValues.publisherId,
				fields: [
					{
						label: localeContent.SITE_NAME_LABEL,
						name: "name",
						isRequired: true,
						gridConfig: { md: 8 }
					},
					{
						label: localeContent.STATUS_LABEL,
						name: "status",
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: [
								{
									value: "active",
									label: localeContent.STATUS_OPTION_LABEL_ACTIVE
								},
								{
									value: "inactive",
									label: localeContent.STATUS_OPTION_LABEL_INACTIVE
								}
							]
						},
						defaultValue: "active"
					},
					{
						label: localeContent.DEFAULT_FLOOR_LABEL,
						fieldType: FieldTypes.CURRENCY,
						name: "defaultFloor",
						gridConfig: { md: 4, lg: 3 },
						tooltip: localeContent.DEFAULT_FLOOR_TOOLTIP
					},
					{
						label: localeContent.REVENUE_SHARE_LABEL,
						fieldType: FieldTypes.PERCENT,
						name: "defaultRevenueShare",
						gridConfig: { md: 4, lg: 3 },
						tooltip: localeContent.DEFAULT_REVENUE_SHARE_TOOLTIP
					},
					{
						label: localeContent.URL_LABEL,
						name: "url",
						gridConfig: { md: 8 },
						tooltip: localeContent.URL_FIELD_TOOLTIP
					}
				]
			}
		]
	},
	{
		label: localeContent.TAB_LABEL_PLACEMENTS,
		key: "PLACEMENTS",
		disableOnCreate: true,
		childResourceConfig: {
			columnsConfig: [
				{
					name: commonLocaleContent.COLUMN_HEADER_STATUS,
					key: COLUMN_KEY_STATUS,
					cellDataType: "upperCase"
				},
				{ name: placementLocaleContent.COLUMN_HEADER_TYPE, key: "type" },
				{ name: placementLocaleContent.SIZE_LABEL, key: "size" }
			],
			resourceEndpoint: "manage/placements",
			resourceRoute: "placements",
			resourceName: commonLocaleContent.PLACEMENT,
			getRequestParams: siteId => ({
				siteId
			})
		}
	}
];

function SitesResourceScreen() {
	return (
		<CrudForm
			resourceEndpoint="manage/sites"
			resourceRoute="sites"
			parentIdField="publisherId"
			parentStatusKey="publisherStatus"
			breadcrumbsConfig={breadcrumbsConfig}
			resourceString={commonLocaleContent.SITE}
			tabsConfig={TABS_CONFIG}
		/>
	);
}

export default SitesResourceScreen;
