import React from "react";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";
import { ACTION_MENU_ITEM_TYPES } from "../../components/GenericTableRowActionMenu/GenericTableRowActionMenu";
import { SERVER_URL_WITH_VERSION } from "../../config/constants";

const placementsColumnsConfig = [
	{ name: commonLocaleContent.SITE, key: "siteName" },
	{ name: commonLocaleContent.PUBLISHER, key: "publisherName" },
	{ name: localeContent.COLUMN_HEADER_TYPE, key: "typeName" },
	{
		name: commonLocaleContent.COLUMN_HEADER_STATUS,
		key: COLUMN_KEY_STATUS,
		cellDataType: "upperCase"
	},
	{
		name: localeContent.COLUMN_HEADER_FLOOR,
		key: "floor",
		cellDataType: "currency"
	},
	{
		name: commonLocaleContent.COLUMN_HEADER_OPPORTUNITIES,
		key: "opportunities",
		cellDataType: "number"
	},
	{
		name: commonLocaleContent.COLUMN_HEADER_IMPRESSIONS,
		key: "impressions",
		cellDataType: "number"
	},
	{
		name: commonLocaleContent.COLUMN_HEADER_REVENUE,
		key: "revenue",
		cellDataType: "currency"
	}
];

const tableRowActionMenuCustomOptions = [
	{
		key: "TAGS-TXT",
		label: commonLocaleContent.ACTION_BUTTON_LABEL_DOWNLOAD_TAGS_TXT,
		accessibilityLabel:
			commonLocaleContent.ACCESSIBILITY_LABEL_TRIGGERS_DOWNLOAD,
		getHref: ({ id }) =>
			`${SERVER_URL_WITH_VERSION}/manage/placements/${id}/tag_file`,
		download: "tags.txt",
		type: ACTION_MENU_ITEM_TYPES.externalLink
	}
];

function PlacementIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.PLACEMENT}
			columnsConfig={placementsColumnsConfig}
			resourceEndpoint="manage/placements"
			resourceRoute="placements"
			parentId="siteId"
			disclaimer={commonLocaleContent.SUPPLY_INDEX_SCREEN_DISCLAIMER_CAPTION}
			tableRowActionMenuCustomOptions={tableRowActionMenuCustomOptions}
			showDataGrid
		/>
	);
}

export default PlacementIndex;
