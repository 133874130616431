import React from "react";
import PropTypes from "prop-types";
import {
	Box,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	Checkbox,
	ListItemText
} from "@mui/material";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";

function CheckboxMenu(props) {
	const {
		menuId,
		menuButtonLabel,
		menuOptions,
		selectedOptions,
		handleMenuOptionToggle,
		menuIconComponent: MenuIconComponent
	} = props;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const isMenuOpen = Boolean(anchorEl);

	const handleColumnsMenuOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleColumnsMenuClose = () => {
		setAnchorEl(null);
	};
	return (
		<Box>
			<IconButton
				aria-label={menuButtonLabel}
				aria-controls={menuId}
				aria-haspopup="true"
				onClick={handleColumnsMenuOpen}
			>
				<MenuIconComponent />
			</IconButton>
			<Menu
				id={menuId}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				open={isMenuOpen}
				onClose={handleColumnsMenuClose}
			>
				{menuOptions.map(menuOption => (
					<MenuItem
						dense
						onClick={handleMenuOptionToggle(menuOption.key)}
						key={menuOption.key}
					>
						<ListItemIcon>
							<Checkbox
								edge="start"
								checked={selectedOptions[menuOption.key]}
								disableRipple
								label={menuOption.name}
								size="small"
								inputProps={{
									"aria-labelledby": `${menuId}-${menuOption.key}-menu-option`
								}}
							/>
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{
								id: `${menuId}-${menuOption.key}-menu-option`
							}}
						>
							{menuOption.name}
						</ListItemText>
					</MenuItem>
				))}
			</Menu>
		</Box>
	);
}

CheckboxMenu.propTypes = {
	menuId: PropTypes.string.isRequired,
	menuButtonLabel: PropTypes.string.isRequired,
	menuOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	selectedOptions: PropTypes.shape(),
	handleMenuOptionToggle: PropTypes.func.isRequired,
	menuIconComponent: PropTypes.shape()
};

CheckboxMenu.defaultProps = {
	menuIconComponent: ViewColumnIcon,
	selectedOptions: {}
};

export default CheckboxMenu;
