export default {
	COLUMN_HEADER_TYPE: "Type",
	COLUMN_HEADER_FLOOR: "Floor ($)",
	DOWNLOAD_PLACEMENT_TAGS_LABEL: "DOWNLOAD TAG",
	SECURE_TAG_LABEL: "Placement Tag",
	SITE_ID_LABEL: "Site",
	SITE_ID_TOOLTIP: "Select the Site which this Placement belongs to.",
	PLACEMENT_NAME_LABEL: "Placement Name",
	STATUS_LABEL: "Status",
	STATUS_OPTION_LABEL_ACTIVE: "Active",
	STATUS_OPTION_LABEL_INACTIVE: "Inactive",
	REVENUE_TYPE_LABEL: "Revenue Type",
	REVENUE_TYPE_TOOLTIP:
		"Determines whether the revenue for the Placement is calculated based on a flat fee or a percentage of gross revenue. When Flat Rate is selected, the revenue share will automatically be set to 0% and the floor price will be used to determine the fee paid to the Publisher.",
	FLOOR_LABEL: "Floor",
	FLOOR_TOOLTIP:
		"Minimum Media CPM which can be aligned to this Placement. If Revenue Type is Flat Rate then the floor is also the CPM paid to the Publisher.",
	REVENUE_SHARE_LABEL: "Revenue Share",
	DEFAULT_REVENUE_SHARE_TOOLTIP:
		"Percentage of gross revenue earned by the Placement.",
	BIDDER_MARGIN_LABEL: "Bidder Margin",
	BIDDER_MARGIN_TOOLTIP:
		"Percentage fee based on the CPM of the Media returned in the bid response",
	TYPE_LABEL: "Type",
	IVT_FILTERING: "IVT Filtering",
	TYPE_TOOLTIP: "Specifies the type of Placement tag which will be generated.",
	IVT_FILTERING_TOOLTIP:
		"If enabled, opportunities that have been marked as fraudulent will be blocked.",
	VAST_VERSION_LABEL: "VAST Version",
	VAST_VERSION_TOOLTIP:
		"Select the version which should be returned in this placement's VAST response.",
	ENABLE_VPAID_LABEL: "Support VPAID",
	SELECT_OPTION_LABEL_YES: "Yes",
	SELECT_OPTION_LABEL_NO: "No",
	SELECT_OPTION_LABEL_TRUE: "Enabled",
	SELECT_OPTION_LABEL_FALSE: "Disabled",
	ENABLE_VPAID_TOOLTIP:
		"Select Yes if your placement will run in environments where VPAID creatives are accepted. If No is selected Programmatic VPAID creatives will not be returned to this placement.",
	ENABLE_C6_AD_MANAGER_LABEL: "Enable C6 Ad Manager",
	ENABLE_C6_AD_MANAGER_TOOLTIP:
		"The C6 Ad Manager provides functionality related to reporting, viewability tracking, waterfall management, domain detection, and more. It is highly recommended that the Ad Manager is enabled for all environments which support VPAID.",
	SIZE_LABEL: "Size",
	SIZE_TOOLTIP:
		"Select the size of the display slot where this Placement tag will run.",
	DEFAULT_INTERCEPT_LABEL: "Default Format",
	DEFAULT_INTERCEPT_TOOLTIP:
		"Default Outstream player format. For more information about each format please click here.",
	MOBILE_INTERCEPT_LABEL: "Mobile Format",
	MOBILE_INTERCEPT_TOOLTIP:
		"Specifies whether mobile players should dock to the top of a user's page in the event that the user scrolls past the player.",
	DOCK_POSITION_LABEL: "Dock Position",
	DOCK_POSITION_TOOLTIP:
		"Determines the section of the screen where a player will appear.",
	CSS_PATH_LABEL: "In-Page CSS Path",
	CSS_PATH_TOOLTIP:
		"Determines the specific location within a webpage where an Outstream player will appear. Select Above Path if you would like the player to appear before, or above, the specified css path, in relation to the page. Select Below Path to show the player after, or below, the specified css path. Applies to Inpage formats only.",
	BEFORE_CSS_PATH_LABEL: "Player Location",
	BEFORE_CSS_PATH_TOOLTIP:
		"Determines if the In-Page video player will be inserted above or below the specified CSS Path",
	IMAGE_LOCATION_LABEL: "In-Page Placeholder Image",
	IMAGE_LOCATION_TOOLTIP:
		"The image will appear, where an In-Page ad unit renders, prior to ad load and after ad complete. Accepted file types are .png, .jpg, .jpeg, .bmp",
	COLLAPSE_IN_PAGE_LABEL: "Collapse In-Page",
	COLLAPSE_IN_PAGE_TOOLTIP:
		"The IAB suggests not shifting page content for the purposes of ad playback, however, by selecting Yes, the space where an In-Page player is placed will be removed after ad playback.",
	CLOSE_BUTTON_LABEL: "Close Button",
	CLOSE_BUTTON_TOOLTIP:
		"Provides users with the ability to manually remove the player from the page during ad playback.",
	ENABLE_AUDIO_LABEL: "Audio",
	ENABLE_AUDIO_TOOLTIP:
		"Ads which enable audio on start can be blocked by browsers and have been demonstrated to encourage the installation of ad blockers.",
	AV_OPT_OUT_LABEL: "Audio Opt-In",
	AV_OPT_OUT_TOOLTIP:
		"Determines whether the ad’s audio is initiated on click or player hover",
	FRAMEBUSTER_LABEL: "Framebuster",
	FRAMEBUSTER_TOOLTIP:
		"Include an iFrame buster if the Placement tag will be required to break out of a non-friendly iframe.",
	VIEW_URL_LABEL: "View URL",
	VIEW_URL_TOOLTIP:
		"Specified URL will be called on Ad Impression. Best used as an additional Impression tracker.",
	CLICK_URL_LABEL: "Click URL",
	CLICK_URL_TOOLTIP:
		"Specified URL will be called on Ad Click. Best used as an additional Click tracker.",
	OUTSTREAM_PASSBACK_URL_LABEL: "Passback",
	OUTSTREAM_PASSBACK_URL_TOOLTIP:
		"If a tag is provided it will be called in the event that the Placement tag does not result in an ad impression.",
	MAX_DURATION_LABEL: "Max Duration",
	MAX_DURATION_TOOLTIP:
		"Maximum length of video ads which can run on this Placement.",
	INSTREAM_SKIP_TIME_LABEL: "Placement Skip",
	INSTREAM_SKIP_TIME_TOOLTIP:
		"Enabling skip allows users to end any ad presented to them at the defined time. Note that by enabling skip you may see lower VTR and bid rates.",
	INSTREAM_SKIP_TIME_RANGE_WARNING: "Must be between 1 and 45 seconds.",
	ENABLE_AD_RESPONSE_MEDIA_LIMIT_LABEL: "Limit Ad Response Size",
	ENABLE_AD_RESPONSE_MEDIA_LIMIT_TOOLTIP:
		"Select Yes to limit the number of ads returned in a given ad response. If No, all available ads will be returned in the ad response.",
	AD_RESPONSE_MEDIA_LIMIT_LABEL: "Max Number of Ads",
	AD_RESPONSE_MEDIA_LIMIT_TOOLTIP:
		"Defines the maximum number of Media which will be returned in this placement's ad response.",
	BROWSER_BLACKLIST_LABEL: "Browser Blacklist",
	MIN_BITRATE_LABEL: "Min Bitrate",
	MIN_BITRATE_TOOLTIP:
		"This value will be used to set the minbitrate in the bid request",
	MAX_BITRATE_LABEL: "Max Bitrate",
	MAX_BITRATE_TOOLTIP:
		"This value will be used to set the maxbitrate in the bid request",
	BROWSER_BLACKLIST_TOOLTIP:
		"List of browsers where this Placement tag is unable to run.",
	ELEMENTS_BLACKLIST_LABEL: "Page Elements Blacklist",
	ELEMENTS_BLACKLIST_TOOLTIP:
		"If the outstream player detects any of the defined elements on the page then outstream execution will be halted before the ad call. Ex: #player_123, .articles_center",
	AUCTION_TYPE_LABEL: "Auction Type",
	AUCTION_TYPE_TOOLTIP:
		"First-Price Auctions result in the winning bidder paying the bid price. Second-Price Auctions result in the winning bidder paying one cent more than the second-highest bid",
	TRACKERS_LABEL: "Trackers",
	TRACKERS_TOOLTIP:
		"Third-party technologies which if implemented can be used to monitor traffic which runs on this Placement.",
	AUCTION_FLOOR_INCREMENT_LABEL: "Auction Floor Increment",
	AUCTION_FLOOR_INCREMENT_TOOLTIP:
		"The value will be added to the value defined with the Floor field to determine the floor price set in a bid request. For example, if Floor = $10 and Auction Floor Increment = $2 then the floor price set in the bid request will be set to $12",
	TARGETING_TYPE_DOMAIN_LABEL: "Domain",
	TARGETING_TYPE_ADVERTISER_DOMAIN_LABEL: "Advertiser Domain",
	TARGETING_TYPE_APP_NAME: "App Name",
	TARGETING_TYPE_APP_BUNDLE_ID: "App Bundle ID",
	MANAGED_DEMAND_TYPE_LABEL: "Managed",
	PROGRAMMATIC_DEMAND_TYPE_LABEL: "Programmatic",
	LIST_TARGETING_RADIO_BUTTON_TOOLTIP:
		"Specify if the defined list should be blocked or allowed to run on a given opportunity",
	PREVENT_DUPLICATE_CREATIVES: "Prevent Duplicate Creatives",
	PREVENT_DUPLICATE_CREATIVES_TOOLTIP:
		"If enabled, multiple Media from the same Campaign will not be allowed to be returned in a single VAST response",
	ALLOW_MULTIPLE_WINNERS_LABEL: "Multiple Winners",
	ALLOW_INVENTORY_PARTNER_DOMAIN: "Inventory Partner Domain",
	ALLOW_INVENTORY_PARTNER_DOMAIN_TOOLTIP:
		"If enabled, the Publisher Domain will be passed in bid requests as inventorypartnerdomain",
	FIELD_MULTIPLE_IMP_OBJECTS_TOOLTIP:
		"If enabled, multiple Imp objects will be included in bid requests originating from this Placement (when applicable)",
	FIELD_MULTIPLE_IMP_OBJECTS_LABEL: "Multiple Imp Objects",
	ALLOW_AUCTION_WIN_BY_CPM_PER_SEC: "CPM Per Second",
	ALLOW_AUCTION_WIN_BY_CPM_PER_SEC_TOOLTIP:
		"If enabled the auction winners from this Placement will be selected based on the highest CPM per second and ads sent back in the Placement response will be prioritized on a CPM per second basis",
	REQUEST_TYPE_RTB: {
		LABEL: "Request Type",
		TOOLTIP:
			"If RTB, all requests to the Placement will be made via oRTB requests",
		OPTIONS: {
			TAG: "Tag",
			RTB: "RTB"
		}
	},
	GUID_LABEL: "Placement UID",
	GUID_TOOLTIP: "UID should be passed with Bidder endpoint",
	CLONE_ALIGNMENTS: {
		LABEL: "Clone Alignments",
		TOOLTIP:
			"If enabled, all associated alignments will be copied to the cloned resource"
	}
};
