export default {
	ENABLE_FIELD_LABEL: "Custom URL Parameter",
	ENABLE_FIELD_TOOLTIP:
		"This field should be used to target specific key/value pairs that are passed in a Placement request. For example, content_genre = comedy.",
	VALIDATION_LEAF_NAME: "key/value group",
	EMPTY_EXPRESSION_WARNING: "Add a Key/Value group to begin creating a rule",
	CUSTOM_LEAF_CREATION: {
		BUTTON_LABEL: "add key/value group",
		DIALOGUE_TITLE: "Key/Value Group",
		DIALOGUE_DESCRIPTION: "Keys may only be used once.",
		KEY_FIELD: {
			LABEL: "Key",
			UNIQUE_KEY_VALIDATION_MESSAGE: "Cannot reuse the same key twice."
		},
		VALUES_FIELD: {
			LABEL: "Values",
			MIN_ONE_ITEM_VALIDATION_MESSAGE: "Must add at least one value"
		}
	},
	RULE_VISUALIZER: {
		TOOLTIP_KEY_LABEL: "Key",
		SHOW_RULE_LABEL: "Show Rule",
		HIDE_RULE_LABEL: "Hide Rule"
	}
};
