import React from "react";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import commonLocaleContent from "../commonLocaleContent";
import localeContent from "./localeContent";
import { ACTION_MENU_ITEM_TYPES } from "../../components/GenericTableRowActionMenu/GenericTableRowActionMenu";
import { SERVER_URL_WITH_VERSION } from "../../config/constants";

const publisherColumnsConfig = [
	{
		name: commonLocaleContent.COLUMN_HEADER_STATUS,
		key: COLUMN_KEY_STATUS,
		cellDataType: "upperCase"
	},
	{
		name: commonLocaleContent.COLUMN_HEADER_OPPORTUNITIES,
		key: "opportunities",
		cellDataType: "number"
	},
	{
		name: commonLocaleContent.COLUMN_HEADER_IMPRESSIONS,
		key: "impressions",
		cellDataType: "number"
	},
	{
		name: commonLocaleContent.COLUMN_HEADER_REVENUE,
		key: "revenue",
		cellDataType: "currency"
	}
];

const publisherChildResourceConfig = {
	name: "Site",
	route: "sites",
	deleteAssociatedResourceWarning: "sites and placements"
};

const tableRowActionMenuCustomOptions = [
	{
		key: "TAGS-TXT",
		label: commonLocaleContent.ACTION_BUTTON_LABEL_DOWNLOAD_TAGS_TXT,
		accessibilityLabel:
			commonLocaleContent.ACCESSIBILITY_LABEL_TRIGGERS_DOWNLOAD,
		getHref: ({ id }) =>
			`${SERVER_URL_WITH_VERSION}/manage/publishers/${id}/tag_file`,
		download: "tags.txt",
		type: ACTION_MENU_ITEM_TYPES.externalLink
	},
	{
		key: "ADS-TXT",
		label: localeContent.ACTION_BUTTON_LABEL_DOWNLOAD_ADS_TXT,
		accessibilityLabel:
			commonLocaleContent.ACCESSIBILITY_LABEL_TRIGGERS_DOWNLOAD,
		getHref: ({ id }) =>
			`${SERVER_URL_WITH_VERSION}/manage/publishers/${id}/ads_txt`,
		download: "ads.txt",
		type: ACTION_MENU_ITEM_TYPES.externalLink
	}
];

function PublisherIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.PUBLISHER}
			columnsConfig={publisherColumnsConfig}
			resourceEndpoint="manage/publishers"
			resourceRoute="publishers"
			disclaimer={commonLocaleContent.SUPPLY_INDEX_SCREEN_DISCLAIMER_CAPTION}
			childResourceConfig={publisherChildResourceConfig}
			tableRowActionMenuCustomOptions={tableRowActionMenuCustomOptions}
			showDataGrid
		/>
	);
}

export default PublisherIndex;
