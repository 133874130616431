import React, { useState, useContext, useMemo } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Container } from "@mui/material";
import { useResourceClient, Operations } from "../../hooks/useResource";
import { useResourceAsync } from "../../hooks/useResourceAsync";
import useFileUpload from "../../hooks/useFileUpload";
import PageTitleBar from "../../components/PageTitleBar/PageTitleBar";
import DataTable from "../../components/DataTable/DataTable";
import TableRowActionDelete from "../../components/DataTable/TableRowActionDelete";
import LoadingBackdrop from "../../components/LoadingBackdrop/LoadingBackdrop";
import { SnackbarContext } from "../../context/SnackbarContext";
import { objectMap } from "../../utils/objectUtils";
import localeContent from "./localeContent";
import validators from "../../utils/validators";
import GlobalBlacklistForm, { KEY_VALUE } from "./GlobalBlacklistForm";

const removeProtocol = domain => domain.replace(/^https?:\/\//, "");

const getEndpoints = baseEndpoint => ({
	fetch: `global-blacklist/${baseEndpoint}/globalBlackList`,
	add: `global-blacklist/${baseEndpoint}/add`,
	delete: `global-blacklist/${baseEndpoint}`,
	file: `manage/global-blacklist/${baseEndpoint}/file/`
});

const TYPES = {
	domain: {
		label: localeContent.GLOBAL_BLACKLIST_TYPE_OPTIONS.DOMAIN_LABEL,
		endpoints: getEndpoints("domain"),
		listResponseListKey: "blacklistDomain",
		createRequestPayloadKey: "domain"
	},
	advertiserDomain: {
		label: localeContent.GLOBAL_BLACKLIST_TYPE_OPTIONS.ADVERTISER_DOMAIN_LABEL,
		endpoints: getEndpoints("advtsr-domain"),
		listResponseListKey: "blacklistAdvertiserDomain",
		createRequestPayloadKey: "domain"
	},
	appName: {
		label: localeContent.GLOBAL_BLACKLIST_TYPE_OPTIONS.APP_NAME_LABEL,
		endpoints: getEndpoints("app_name"),
		listResponseListKey: "blacklistAppName",
		createRequestPayloadKey: "appName"
	},
	appBundleId: {
		label: localeContent.GLOBAL_BLACKLIST_TYPE_OPTIONS.APP_BUNDLE_ID_LABEL,
		endpoints: getEndpoints("app_bundle_id"),
		listResponseListKey: "blacklistAppBundle",
		createRequestPayloadKey: "appBundleId"
	}
};

const TYPE_OPTIONS = Object.entries(TYPES).map(([typeKey, typeConfig]) => ({
	value: typeKey,
	label: typeConfig.label
}));

const { value: INITIAL_TYPE_VALUE } = TYPE_OPTIONS[0];

Yup.addMethod(Yup.string, "domainValidation", function customRangeValidation(
	message
) {
	return this.test(
		"domainValidation",
		message,
		function testCustomRangeValidation(value) {
			if (value && !validators.validateDomain(removeProtocol(value))) {
				throw this.createError({
					path: this.path,
					message
				});
			}
			return true;
		}
	);
});

const domainValidationSchema = Yup.object({
	[KEY_VALUE]: Yup.string()
		.required(localeContent.VALUE_REQUIRED_MESSAGE)
		.domainValidation(localeContent.DOMAIN_INVALID_MESSAGE)
});

const requiredValidationSchema = Yup.object({
	[KEY_VALUE]: Yup.string().required(localeContent.VALUE_REQUIRED_MESSAGE)
});

const initialValues = {
	[KEY_VALUE]: ""
};

const validationSchema = {
	domain: domainValidationSchema,
	advertiserDomain: domainValidationSchema,
	appName: requiredValidationSchema,
	appBundleId: requiredValidationSchema
};

function GlobalBlacklistResource() {
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const [type, setType] = useState(INITIAL_TYPE_VALUE);

	const [blacklistData, , isFetching, fetchBlacklist] = useResourceClient(
		`manage/${TYPES[type].endpoints.fetch}`,
		Operations.LIST,
		{}
	);

	const { execute: submitFormData } = useResourceAsync(
		`manage/${TYPES[type].endpoints.add}`,
		Operations.CREATE
	);

	const {
		uploadFileAsync,
		isSubmitting: isBulkUploadSubmitting
	} = useFileUpload(TYPES[type].endpoints.file);

	const currentTypeLabel = TYPE_OPTIONS.find(({ value }) => value === type)
		.label;

	const blacklistRowData = blacklistData?.[
		TYPES[type].listResponseListKey
	]?.map(item => ({
		id: item,
		value: item
	}));

	const onChangeType = value => {
		setType(value);
		fetchBlacklist({});
	};

	const columnsConfig = useMemo(
		() => [
			{
				key: "actions",
				padding: "checkbox",
				preventSort: true,
				formatter: (value, rowData) => {
					return (
						<TableRowActionDelete
							rowData={rowData}
							resourceName={localeContent.GLOBAL_BLACKLIST_NAME}
							deleteEndpoint={`manage/${TYPES[type].endpoints.delete}`}
							deleteSuccessMessage={localeContent.BLACKLIST_DELETED_SUCCESS_MESSAGE(
								{ type: currentTypeLabel, typeValue: rowData.value }
							)}
							updateTableData={() => fetchBlacklist({})}
						/>
					);
				}
			},
			{ key: "id", name: currentTypeLabel }
		],
		[currentTypeLabel, fetchBlacklist, type]
	);

	const uploadFile = async file => {
		const { data: uploadedFile, error } = await uploadFileAsync({
			file,
			id: "globalBlackList"
		});
		if (error) {
			const { message } = error;
			if (message) {
				triggerNewSnackbarMessage({ message, severity: "error" });
			}
		} else {
			const {
				validCount,
				duplicateCount,
				invalidCount
			} = uploadedFile.successDto;
			fetchBlacklist({});
			triggerNewSnackbarMessage({
				message: localeContent.BLACKLIST_FILE_UPLOAD_SUCCESS_MESSAGE({
					validCount,
					duplicateCount,
					invalidCount
				}),
				severity: "success"
			});
		}
	};

	return (
		<>
			<LoadingBackdrop
				isOpen={isFetching || isBulkUploadSubmitting}
				testId="fetching-blacklist"
			/>
			<PageTitleBar barTitle={localeContent.GLOBAL_BLACKLIST_TITLE} />
			<Container maxWidth="lg">
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={validationSchema[type]}
					onSubmit={async (values, actions) => {
						const { setErrors, resetForm } = actions;
						const { data, isSuccess, error } = await submitFormData({
							data: {
								[TYPES[type].createRequestPayloadKey]: removeProtocol(
									values[KEY_VALUE]
								)
							}
						});
						if (error) {
							const { detail, message } = error;
							if (detail) {
								setErrors(objectMap(detail, value => value.join(" - ")));
							} else if (message) {
								triggerNewSnackbarMessage({ message, severity: "error" });
							}
						}
						if (isSuccess) {
							if (data.errors) {
								triggerNewSnackbarMessage({
									message: data.errors.domain[0],
									severity: "error"
								});
							} else {
								fetchBlacklist({});
								triggerNewSnackbarMessage({
									message: localeContent.BLACKLIST_ADDED_SUCCESS_MESSAGE({
										type: currentTypeLabel,
										typeValue: values[KEY_VALUE]
									}),
									severity: "success"
								});
								resetForm({ values: initialValues });
							}
						}
					}}
				>
					<GlobalBlacklistForm
						typeOptions={TYPE_OPTIONS}
						typeValue={type}
						typeLabel={currentTypeLabel}
						onChangeType={onChangeType}
						onFileUpload={uploadFile}
					/>
				</Formik>
				<DataTable
					columnsConfig={columnsConfig}
					rowData={blacklistRowData}
					accessibleTableDescription="global blacklist table"
					searchColumnKey="id"
					paginationConfig={{}}
					itemCountLabel={currentTypeLabel}
					disableColumnToggleMenu
				/>
			</Container>
		</>
	);
}

GlobalBlacklistResource.propTypes = {};

GlobalBlacklistResource.defaultProps = {};

export default GlobalBlacklistResource;
