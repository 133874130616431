import React, { useContext } from "react";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";
import { routersEndpoint } from "../../utils/endpointUtils";
import { AuthContext } from "../../context/AuthContext";

const routersColumnsConfig = [
	{
		name: commonLocaleContent.COLUMN_HEADER_STATUS,
		key: COLUMN_KEY_STATUS,
		cellDataType: "upperCase"
	},
	{ name: localeContent.COLUMN_HEADER_TYPE, key: "type" },
	{ name: localeContent.COLUMN_HEADER_REQUESTS, key: "requests" }
];

function RouterIndex() {
	const authContext = useContext(AuthContext);
	const { companyId } = authContext;

	const routerEndPoint = `model/company/${companyId}/routers`;

	return (
		<CrudIndex
			resourceName={commonLocaleContent.ROUTER}
			columnsConfig={routersColumnsConfig}
			resourceEndpoint={routerEndPoint}
			resourceRoute="routers"
			customStartPathEndPoint={routersEndpoint}
			disableClone
			showDataGrid
		/>
	);
}

export default RouterIndex;
