import React from "react";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import commonLocaleContent from "../commonLocaleContent";

const roleColumnsConfig = [
	{ key: "description", name: commonLocaleContent.COLUMN_HEADER_DESCRIPTION }
];

function RoleIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.ROLE}
			columnsConfig={roleColumnsConfig}
			resourceEndpoint="manage/user-roles"
			resourceRoute="user-roles"
			disableDelete
			showDataGrid
		/>
	);
}

export default RoleIndex;
