import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Form, useFormikContext } from "formik";
import { Container, Typography, Box } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

import { getFieldTypeOrDefault } from "./FieldTypes";
import PageTitleBar from "../PageTitleBar/PageTitleBar";
import AuditEvent from "../AuditEvent/AuditEvent";
import FormFieldsPanel from "./FormFieldsPanel";
import TabPanelComponentWrapper from "./TabPanelComponentWrapper";
import TabPanel from "../TabPanel/TabPanel";
import ChildResourcePanel from "./ChildResourcePanel";
import DirtyFormProtector from "./DirtyFormProtector";
import localeContent from "./localeContent";
import { isEmpty } from "../../utils/objectUtils";

function getDefaultActiveTabKey(tabsConfig) {
	return isEmpty(tabsConfig) ? "DEFAULT" : tabsConfig[0].key;
}

const isTabDisabled = (tabConfig, formValues, isCreateResource) =>
	tabConfig.disableIf
		? tabConfig.disableIf(formValues)
		: tabConfig.disableOnCreate && isCreateResource;

const isFieldArrayInError = (fieldErrors, fieldTouched) =>
	fieldErrors.some((errorRow, index) =>
		Object.entries(errorRow || {}).some(
			([fieldName, error]) =>
				error && fieldTouched[index] && fieldTouched[index][fieldName]
		)
	);

const isFieldObjectInError = (fieldErrors, fieldTouched) =>
	Object.entries(fieldErrors).some(
		([fieldName, error]) => error && fieldTouched[fieldName]
	);

const isObject = test => {
	return typeof test === "object" && test !== null;
};

/**
 * Reduces fieldGroupsConfig to a flat map of field names, with values set by a custom callback
 * @param {Array} fieldGroupsConfig an array of field group config objects, each containing an array of individual field config objects
 * @param {Function} setValue called for each field object, sets value of field name in map
 */
const mapFieldsConfigToFieldNames = (setValue, fieldGroupsConfig = []) => {
	return fieldGroupsConfig.reduce(
		(fieldsConfig, fieldGroup) => ({
			...fieldsConfig,
			...fieldGroup.fields.reduce(
				(map, fieldConfig) => ({
					...map,
					[fieldConfig.name]: setValue(fieldConfig)
				}),
				{}
			)
		}),
		{}
	);
};

const defaultIncrementTabErrorCount = (errorFieldName, errors, touched) => {
	const fieldError = errors[errorFieldName];
	const fieldTouched = touched[errorFieldName];
	// If we have a fieldArray, we need to validate rows, each with their own individual fields
	if (Array.isArray(fieldError) && fieldTouched) {
		return isFieldArrayInError(fieldError, fieldTouched);
	}
	// If we have an object, we need to validate each key
	if (isObject(fieldError) && fieldTouched) {
		return isFieldObjectInError(fieldError, fieldTouched);
	}
	// Otherwise, (for most cases) it's sufficient to check for an error and touched status on the field directly
	return fieldError && fieldTouched;
};

const getTabErrorCount = (tabConfig, errors, touched) => {
	// Create a map of fieldName -> incrementBarTitleErrorCountFunction
	const validationConfig = mapFieldsConfigToFieldNames(fieldConfig => {
		const fieldType = getFieldTypeOrDefault(fieldConfig);
		// use custom function
		if (fieldType.incrementBarTitleErrorCount) {
			return fieldType.incrementBarTitleErrorCount;
		}
		// use default function
		return defaultIncrementTabErrorCount;
	}, tabConfig.fieldsConfig);
	// Iterate through each field and run a function to determine if it should count towards the total error count
	return Object.entries(
		validationConfig
	).filter(([errorFieldName, incrementBarTitleErrorCountFunction]) =>
		incrementBarTitleErrorCountFunction(errorFieldName, errors, touched)
	).length;
};

const getPageTitleBarTabsConfig = (
	tabsConfig,
	values,
	errors,
	touched,
	isCreateResource
) => {
	if (isEmpty(tabsConfig)) return null;
	return tabsConfig.map(tabConfig => {
		const { key, label } = tabConfig;

		return {
			key,
			label,
			disabled: isTabDisabled(tabConfig, values, isCreateResource),
			errorCount: getTabErrorCount(tabConfig, errors, touched)
		};
	});
};

function CrudFormFormik(props) {
	const {
		formContext,
		initData,
		initError,
		barTitle,
		tabsConfig,
		fieldsConfig,
		setRedirectOverride,
		resourceString,
		showFormContent,
		resourceId,
		breadCrumbs,
		flatFieldsConfig
	} = props;
	const {
		errors,
		touched,
		values,
		dirty,
		submitForm,
		isSubmitting,
		setFieldValue
	} = useFormikContext();
	const { isCreateResource, dirtyOverride } = formContext;

	const [activeTabKey, setActiveTabKey] = React.useState(
		getDefaultActiveTabKey(tabsConfig)
	);
	const handleTabChange = (event, newValue) => {
		setActiveTabKey(newValue);
	};

	const defaultDirtyConfig = useMemo(
		() => ({
			when: dirty,
			resourceString,
			submitForm: path => {
				setRedirectOverride(path);
				submitForm();
			}
		}),
		[dirty, resourceString, setRedirectOverride, submitForm]
	);
	const dirtyConfig = { ...defaultDirtyConfig, ...dirtyOverride };

	const pageTitleBarTabsConfig = getPageTitleBarTabsConfig(
		tabsConfig,
		values,
		errors,
		touched,
		isCreateResource
	);

	/**
	 * memoize a map of fieldName => dependentFieldValueSetter function
	 * as the dependentFieldValueSetter functions will be called on every formValue change, we want to be as efficient as possible
	 */
	const dependentFieldValueSetters = useMemo(
		() =>
			flatFieldsConfig.reduce((agg, fieldConfig) => {
				const { dependentFieldValueSetter, name } = fieldConfig;
				if (dependentFieldValueSetter) {
					return {
						...agg,
						[name]: dependentFieldValueSetter
					};
				}
				return agg;
			}, {}),
		[flatFieldsConfig]
	);

	// Iterate through each dependentFieldValueSetter on any change to form values
	useEffect(() => {
		Object.entries(dependentFieldValueSetters).forEach(
			([fieldName, dependentFieldValueSetter]) => {
				// Provide a function that only lets the field update its own value
				const setValue = value => {
					setFieldValue(fieldName, value);
				};

				dependentFieldValueSetter(values, setValue);
			}
		);
	}, [values, setFieldValue, dependentFieldValueSetters]);

	return (
		<Form noValidate>
			<PageTitleBar
				barTitle={barTitle}
				actionButtonText={localeContent.SAVE_BUTTON_LABEL}
				actionButtonType="submit"
				actionButtonIconComponent={SaveIcon}
				isActionButtonDisabled={isSubmitting}
				tabsConfig={pageTitleBarTabsConfig}
				activeTabKey={activeTabKey}
				handleTabChange={handleTabChange}
				breadCrumbs={breadCrumbs}
			/>
			{showFormContent &&
				(initError ? (
					// This is a temporary solution pending creation of an error handling ticket
					<Typography>{initError}</Typography>
				) : (
					<>
						{tabsConfig.map((tabConfig, index) => (
							<Container
								key={tabConfig.key}
								maxWidth={tabConfig.useContainer !== false ? "lg" : false}
							>
								<TabPanel tabKey={tabConfig.key} activeTabKey={activeTabKey}>
									{index === 0 && formContext.auditEvent && (
										<Box mt={2} mb={1}>
											<AuditEvent auditEvent={formContext.auditEvent} />
										</Box>
									)}
									{tabConfig.componentConfigTop && (
										<TabPanelComponentWrapper
											componentConfig={tabConfig.componentConfigTop}
											resourceId={resourceId}
											formContext={formContext}
										/>
									)}
									{tabConfig.fieldsConfig && (
										<FormFieldsPanel
											fieldsConfig={tabConfig.fieldsConfig}
											formValues={values}
											formContext={formContext}
											initData={initData}
										/>
									)}

									{tabConfig.componentConfig && (
										<TabPanelComponentWrapper
											componentConfig={tabConfig.componentConfig}
											resourceId={resourceId}
											formContext={formContext}
										/>
									)}
									{/* TODO: CP-1236 Use componentConfig approach for childResourceConfig */}
									{tabConfig.childResourceConfig && (
										<ChildResourcePanel
											childResourceConfig={tabConfig.childResourceConfig}
											resourceId={resourceId}
										/>
									)}
								</TabPanel>
							</Container>
						))}
						{tabsConfig.length === 0 && (
							<Container maxWidth="lg">
								{formContext?.auditEvent && (
									<AuditEvent auditEvent={formContext.auditEvent} />
								)}
								<FormFieldsPanel
									fieldsConfig={fieldsConfig}
									formValues={values}
									formContext={formContext}
									initData={initData}
								/>
							</Container>
						)}
					</>
				))}
			<DirtyFormProtector
				when={dirtyConfig.when}
				resourceString={dirtyConfig.resourceString}
				submitForm={dirtyConfig.submitForm}
			/>
		</Form>
	);
}

CrudFormFormik.propTypes = {
	formContext: PropTypes.shape().isRequired,
	initData: PropTypes.shape(),
	initError: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape(),
		PropTypes.bool
	]),
	barTitle: PropTypes.string.isRequired,
	tabsConfig: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	fieldsConfig: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	setRedirectOverride: PropTypes.func.isRequired,
	resourceString: PropTypes.string.isRequired,
	resourceId: PropTypes.string,
	showFormContent: PropTypes.bool,
	breadCrumbs: PropTypes.arrayOf(PropTypes.shape()),
	flatFieldsConfig: PropTypes.arrayOf(PropTypes.shape())
};

CrudFormFormik.defaultProps = {
	initData: {},
	initError: null,
	resourceId: null,
	showFormContent: true,
	breadCrumbs: [],
	flatFieldsConfig: []
};

export default CrudFormFormik;
