import React, { useState, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { Paper, Typography, Tooltip, Link } from "@mui/material";
import Box from "@mui/material/Box";
import {
	DataGridPro as DataGrid,
	GridFooterContainer,
	GridFooter,
	GridPagination,
	useGridApiContext,
	useGridSelector,
	gridPageCountSelector,
	GridToolbarQuickFilter,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector
} from "@mui/x-data-grid-pro";
import MuiPagination from "@mui/material/Pagination";
import AddIcon from "@mui/icons-material/Add";
import RouterLink from "../Routing/LinkWithQuery";
import {
	formatAsCurrency,
	formatAsNumberWithSeparator,
	formatAsDate,
	formatAsEnabled,
	formatAsCapitalized
} from "../../utils/formatters";
import { AuthenticatedUserSettingsContext } from "../../context/AuthenticatedUserSettingsContext";

function DataGridTitle(props) {
	const { heading } = props;
	return (
		<Box
			style={{
				width: "100%",
				display: "flex",
				justifyContent: "left",
				alignItems: "left",
				marginLeft: "7px"
			}}
		>
			<Typography>{heading}</Typography>
		</Box>
	);
}

DataGridTitle.propTypes = { heading: PropTypes.string };
DataGridTitle.defaultProps = { heading: null };

function GridToolbarCustom(props) {
	const { toolbarMessage, setColumnsButtonEl, setFilterActive } = props;
	return (
		<Box mt={1.2} mb={0.5}>
			<DataGridTitle heading={toolbarMessage} />
			<GridToolbarContainer sx={{ justifyContent: "space-between" }}>
				<GridToolbarQuickFilter />
				<Box>
					<GridToolbarColumnsButton
						ref={setColumnsButtonEl}
						onClick={() => {
							setFilterActive(false);
						}}
					/>
					<GridToolbarDensitySelector />
				</Box>
			</GridToolbarContainer>
		</Box>
	);
}

GridToolbarCustom.propTypes = {
	toolbarMessage: PropTypes.string,
	setColumnsButtonEl: PropTypes.func,
	setFilterActive: PropTypes.func
};
GridToolbarCustom.defaultProps = {
	toolbarMessage: null,
	setColumnsButtonEl: () => {},
	setFilterActive: () => {}
};

const updateColumn = column => {
	const {
		key,
		name,
		resourceRoute,
		formatter,
		cellDataType,
		width,
		hide
	} = column;
	if ((key === "name" || key === "fileName") && resourceRoute) {
		return {
			hideable: false,
			headerAlign: "left",
			align: "left",
			field: key,
			headerName: name,
			default: true,
			sortable: true,
			minWidth: 200,
			width: width || 300,
			flex: 0.75,
			renderCell: params => (
				<Link
					to={`/dashboard/${resourceRoute}/${params.id}`}
					component={RouterLink}
					color="inherit"
				>
					{params.value && (
						<Tooltip title={params.value}>
							<span className="table-cell-truncate">{params.value}</span>
						</Tooltip>
					)}
				</Link>
			)
		};
	}
	if (key === "actions") {
		return {
			hideable: false,
			headerAlign: "center",
			align: "center",
			field: key,
			width: 30,
			headerName: "",
			default: true,
			sortable: false,
			minWidth: 30,
			renderCell: params => formatter(null, params)
		};
	}
	if (column.key === "guid") {
		return {
			hideable: false,
			headerAlign: "center",
			align: "center",
			field: key,
			width: width || 50,
			headerName: name,
			default: true,
			sortable: false,
			minWidth: 50,
			hide: hide || true
		};
	}
	switch (cellDataType) {
		case "date":
			return {
				headerAlign: "left",
				align: "left",
				field: key,
				width: width || 120,
				headerName: name,
				default: true,
				sortable: true,
				minWidth: 50,
				valueFormatter: ({ value }) => formatAsDate(value)
			};
		case "upperCase":
			return {
				headerAlign: "left",
				align: "left",
				field: key,
				width: width || 100,
				headerName: name,
				default: true,
				sortable: true,
				minWidth: 50,
				valueFormatter: ({ value }) => formatAsCapitalized(value)
			};
		case "number":
			return {
				headerAlign: "right",
				align: "right",
				field: key,
				width: width || 120,
				headerName: name,
				default: true,
				sortable: true,
				minWidth: 100,
				valueFormatter: ({ value }) => formatAsNumberWithSeparator(value)
			};
		case "enabled":
			return {
				headerAlign: "left",
				align: "left",
				field: key,
				width: width || 120,
				headerName: name,
				default: true,
				sortable: true,
				minWidth: 50,
				valueFormatter: ({ value }) => formatAsEnabled(value)
			};
		case "currency":
			return {
				headerAlign: "right",
				align: "right",
				field: key,
				width: width || 120,
				headerName: name,
				default: true,
				sortable: true,
				minWidth: 50,
				valueFormatter: ({ value }) => formatAsCurrency(value)
			};
		default:
			return {
				headerAlign: "left",
				align: "left",
				field: key,
				headerName: name,
				default: true,
				sortable: true,
				minWidth: 150,
				width: width || 200,
				flex: 0.5,
				renderCell: params =>
					params.value && (
						<Tooltip title={params.value}>
							<span className="table-cell-truncate">{params.value}</span>
						</Tooltip>
					)
			};
	}
};

function Pagination({ page, onPageChange, className }) {
	const apiRef = useGridApiContext();
	const pageCount = useGridSelector(apiRef, gridPageCountSelector);

	return (
		<MuiPagination
			color="primary"
			className={className}
			count={pageCount}
			page={page + 1}
			onChange={(event, newPage) => {
				onPageChange(event, newPage - 1);
			}}
		/>
	);
}

Pagination.propTypes = {
	page: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	className: PropTypes.string.isRequired
};

function CustomPagination(props) {
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <GridPagination ActionsComponent={Pagination} {...props} />;
}

function CustomFooterStatusComponent(props) {
	const { footerMessage } = props;
	return (
		<GridFooterContainer>
			<Box
				sx={theme => ({
					flexGrow: 1,
					flexShrink: 1,
					color: theme.palette.grey[800]
				})}
			>
				<Typography variant="caption">{footerMessage}</Typography>
			</Box>
			<GridFooter
				sx={{
					border: "none"
				}}
			/>
		</GridFooterContainer>
	);
}

CustomFooterStatusComponent.propTypes = {
	footerMessage: PropTypes.string
};
CustomFooterStatusComponent.defaultProps = {
	footerMessage: null
};

function CrudDataGrid(props) {
	const {
		disclaimer,
		columns,
		filteredData,
		paginationConfig,
		heading,
		gridHeight,
		tableTestId
	} = props;
	const newColumnConfig = useMemo(() => {
		return columns.map(column => updateColumn(column));
	}, [columns]);
	const isUpdatedAt = columns.find(column => column.key === "updatedAt");
	const [sortModel, setSortModel] = useState([
		{
			field: isUpdatedAt ? "updatedAt" : "createdAt",
			sort: "desc"
		}
	]);
	const [columnsButtonEl, setColumnsButtonEl] = React.useState(null);
	filteredData.map(data => {
		data.key = `data-grid-key-${data.id}`;
		return data;
	});
	const [pageSize, handlePageSizeChange] = React.useState(
		paginationConfig.defaultRowsPerPage || 10
	);
	const pageOptions = [paginationConfig.defaultRowsPerPage || 10, 20, 50];
	const { defaultDensity } = useContext(AuthenticatedUserSettingsContext);

	const rowsPerPageOptions =
		filteredData.length <= 50
			? pageOptions
			: [...pageOptions, filteredData.length];
	return (
		<Paper
			sx={{
				height: gridHeight || 720,
				my: 3,
				px: 2
			}}
			data-testid={tableTestId || "indexTableId"}
		>
			<Box sx={{ display: "flex", height: "100%" }}>
				<DataGrid
					disableColumnFilter
					rows={filteredData}
					columns={newColumnConfig}
					pageSize={pageSize}
					rowsPerPageOptions={rowsPerPageOptions}
					onSortModelChange={setSortModel}
					sortModel={sortModel}
					sortingOrder={["desc", "asc"]}
					pagination
					onPageSizeChange={handlePageSizeChange}
					density={defaultDensity}
					components={{
						Footer: CustomFooterStatusComponent,
						Pagination: CustomPagination,
						MoreActionsIcon: AddIcon,
						Toolbar: GridToolbarCustom
					}}
					componentsProps={{
						footer: { footerMessage: disclaimer },
						toolbar: { toolbarMessage: heading, setColumnsButtonEl },
						panel: {
							anchorEl: columnsButtonEl
						}
					}}
					sx={{
						border: "none",
						".MuiDataGrid-columnHeaders": {
							backgroundColor: "primary.800",
							color: "common.white"
						},
						".MuiDataGrid-row:nth-of-type(even)": {
							backgroundColor: "secondary.300"
						},
						".MuiDataGrid-sortIcon": {
							color: "common.white"
						},
						".MuiDataGrid-menuIconButton": {
							color: "common.white"
						},
						".MuiDataGrid-columnHeaderTitle": {
							marginLeft: "10px"
						}
					}}
				/>
			</Box>
		</Paper>
	);
}

CrudDataGrid.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	filteredData: PropTypes.arrayOf(PropTypes.shape()),
	disclaimer: PropTypes.string,
	paginationConfig: PropTypes.shape({
		defaultRowsPerPage: PropTypes.number
	}),
	heading: PropTypes.string,
	gridHeight: PropTypes.number,
	tableTestId: PropTypes.string
};
CrudDataGrid.defaultProps = {
	disclaimer: null,
	filteredData: [],
	paginationConfig: {},
	heading: null,
	gridHeight: null,
	tableTestId: null
};

export default CrudDataGrid;
