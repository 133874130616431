import {
	formatAsCurrency,
	formatAsNumberWithSeparator,
	formatAsPercent
} from "../../utils/formatters";
import lc from "./localeContent";
import { safeDivide } from "../../utils/objectUtils";
import { REPORT_TYPE_OPTIONS_ENUM } from "../../config/constants";
import validators from "../../utils/validators";

export const METRIC_TYPES = {
	CURRENCY: "currency",
	PERCENT: "percent",
	NUMBER: "number"
};

const VALUE_FORMATTERS = {
	[METRIC_TYPES.CURRENCY]: ({ value }, precisionDigit) =>
		formatAsCurrency(value, null, precisionDigit),
	[METRIC_TYPES.PERCENT]: ({ value }) => formatAsPercent(value),
	[METRIC_TYPES.NUMBER]: ({ value }) => formatAsNumberWithSeparator(value)
};

export const calculateCPM = (impressions, revenue) => {
	return safeDivide(revenue * 1000, impressions);
};

export const calculatePotentialFillRate = (opportunities, potentialToFill) => {
	return safeDivide(potentialToFill, opportunities);
};

const calculatedCostWithMultiplier = (opportunities, costMultiplier) => {
	if (
		validators.isZeroOrUndefinedNumber(costMultiplier) ||
		validators.isZeroOrUndefinedNumber(opportunities)
	) {
		return 0;
	}
	return opportunities * costMultiplier;
};

const metricsHeaderDefaults = {
	headerAlign: "right",
	align: "right"
};

export const REPORTING_METRIC = {
	OPPORTUNITIES: {
		field: "opportunities",
		width: 135,
		name: lc.METRIC_LABELS.OPPORTUNITIES,
		description: lc.METRICS_TOOLTIPS.OPPORTUNITIES,
		dataType: METRIC_TYPES.NUMBER
	},
	IMPRESSIONS: {
		field: "impressions",
		width: 125,
		name: lc.METRIC_LABELS.IMPRESSIONS,
		description: lc.METRICS_TOOLTIPS.IMPRESSIONS,
		dataType: METRIC_TYPES.NUMBER
	},
	FILL_RATE: {
		field: "fillrate",
		width: 100,
		name: lc.METRIC_LABELS.FILL_RATE,
		description: lc.METRICS_TOOLTIPS.FILL_RATE,
		getDerivedValue: row => safeDivide(row.impressions, row.opportunities),
		dataType: METRIC_TYPES.PERCENT
	},
	GROSS_CPM: {
		field: "grosscpm",
		width: 140,
		name: lc.METRIC_LABELS.GROSS_CPM,
		description: lc.METRICS_TOOLTIPS.GROSS_CPM,
		getDerivedValue: row => calculateCPM(row.impressions, row.grossrevenue),
		dataType: METRIC_TYPES.CURRENCY
	},
	GROSS_REVENUE: {
		field: "grossrevenue",
		width: 160,
		name: lc.METRIC_LABELS.GROSS_REVENUE,
		demandClientName: lc.DEMAND_CLIENT_METRIC_LABELS.GROSS_REVENUE,
		description: lc.METRICS_TOOLTIPS.GROSS_REVENUE,
		dataType: METRIC_TYPES.CURRENCY
	},
	RTB_OPPORTUNITIES: {
		field: "rtbOpportunities",
		width: 130,
		name: lc.METRIC_LABELS.RTB_OPPORTUNITIES,
		description: lc.METRICS_TOOLTIPS.BID_REQUESTS,
		dataType: METRIC_TYPES.NUMBER
	},
	RTB_BIDS: {
		field: "rtbBids",
		width: 75,
		name: lc.METRIC_LABELS.RTB_BIDS,
		description: lc.METRICS_TOOLTIPS.RTB_BIDS,
		dataType: METRIC_TYPES.NUMBER
	},
	WINS: {
		field: "wins",
		width: 75,
		name: lc.METRIC_LABELS.WINS,
		description: lc.METRICS_TOOLTIPS.WINS,
		valueFormatter: VALUE_FORMATTERS.number
	},
	BID_RATE: {
		field: "bidrate",
		minWidth: 95,
		name: lc.METRIC_LABELS.BID_RATE,
		description: lc.METRICS_TOOLTIPS.BID_RATE,
		getDerivedValue: row => safeDivide(row.rtbBids, row.rtbOpportunities),
		dataType: METRIC_TYPES.PERCENT
	},
	WIN_RATE: {
		field: "winrate",
		minWidth: 100,
		name: lc.METRIC_LABELS.WIN_RATE,
		description: lc.METRICS_TOOLTIPS.WIN_RATE,
		getDerivedValue: row => safeDivide(row.wins, row.rtbBids),
		dataType: METRIC_TYPES.PERCENT
	},
	CLOSE_RATE: {
		field: "closerate",
		minWidth: 110,
		name: lc.METRIC_LABELS.CLOSE_RATE,
		description: lc.METRICS_TOOLTIPS.CLOSE_RATE,
		getDerivedValue: row => safeDivide(row.impressions, row.wins),
		dataType: METRIC_TYPES.PERCENT
	},
	CLOSE_CPM: {
		field: "winprice",
		width: 135,
		name: lc.METRIC_LABELS.CLOSE_CPM,
		description: lc.METRICS_TOOLTIPS.CLOSE_CPM,
		getDerivedValue: row => calculateCPM(row.impressions, row.winrevenue),
		dataType: METRIC_TYPES.CURRENCY
	},
	CLOSE_REVENUE: {
		field: "winrevenue",
		width: 155,
		name: lc.METRIC_LABELS.CLOSE_REVENUE,
		description: lc.METRICS_TOOLTIPS.CLOSE_REVENUE,
		dataType: METRIC_TYPES.CURRENCY
	},
	PUBLISHER_CPM: {
		field: "pubcpm",
		width: 155,
		name: lc.METRIC_LABELS.PUBLISHER_CPM,
		description: lc.METRICS_TOOLTIPS.PUBLISHER_CPM,
		getDerivedValue: row => calculateCPM(row.impressions, row.revenue),
		dataType: METRIC_TYPES.CURRENCY
	},
	PUBLISHER_REVENUE: {
		field: "pubrevenue",
		width: 180,
		name: lc.METRIC_LABELS.PUBLISHER_REVENUE,
		description: lc.METRICS_TOOLTIPS.PUBLISHER_REVENUE,
		dataType: METRIC_TYPES.CURRENCY,
		getDerivedValue: row => row.revenue
	},
	NET_MARGIN: {
		field: "margin",
		width: 115,
		name: lc.METRIC_LABELS.NET_MARGIN,
		description: lc.METRICS_TOOLTIPS.NET_MARGIN,
		dataType: METRIC_TYPES.PERCENT
	},
	NET_REVENUE: {
		field: "netRevenue",
		width: 145,
		name: lc.METRIC_LABELS.NET_REVENUE,
		description: lc.METRICS_TOOLTIPS.NET_REVENUE,
		dataType: METRIC_TYPES.CURRENCY
	},
	CLICKS: {
		field: "clicks",
		width: 80,
		name: lc.METRIC_LABELS.CLICKS,
		description: lc.METRICS_TOOLTIPS.CLICKS,
		dataType: METRIC_TYPES.NUMBER
	},
	CTR: {
		field: "ctr",
		width: 75,
		name: lc.METRIC_LABELS.CTR,
		description: lc.METRICS_TOOLTIPS.CTR,
		getDerivedValue: row => safeDivide(row.clicks, row.impressions),
		dataType: METRIC_TYPES.PERCENT
	},
	QUARTILE0: {
		field: "quartile0",
		width: 115,
		name: lc.METRIC_LABELS.QUARTILE_0,
		description: lc.METRICS_TOOLTIPS.QUARTILE_0,
		getDerivedValue: row => safeDivide(row.quartile0, row.impressions),
		dataType: METRIC_TYPES.PERCENT
	},
	QUARTILE25: {
		field: "quartile25",
		width: 120,
		name: lc.METRIC_LABELS.QUARTILE_25,
		description: lc.METRICS_TOOLTIPS.QUARTILE_25,
		getDerivedValue: row => safeDivide(row.quartile25, row.impressions),
		dataType: METRIC_TYPES.PERCENT
	},
	QUARTILE50: {
		field: "quartile50",
		width: 120,
		getDerivedValue: row => safeDivide(row.quartile50, row.impressions),
		name: lc.METRIC_LABELS.QUARTILE_50,
		description: lc.METRICS_TOOLTIPS.QUARTILE_50,
		dataType: METRIC_TYPES.PERCENT
	},
	QUARTILE75: {
		field: "quartile75",
		width: 120,
		name: lc.METRIC_LABELS.QUARTILE_75,
		description: lc.METRICS_TOOLTIPS.QUARTILE_75,
		getDerivedValue: row => safeDivide(row.quartile75, row.impressions),
		dataType: METRIC_TYPES.PERCENT
	},
	QUARTILE100: {
		field: "quartile100",
		width: 130,
		getDerivedValue: row => safeDivide(row.quartile100, row.impressions),
		name: lc.METRIC_LABELS.QUARTILE_100,
		description: lc.METRICS_TOOLTIPS.QUARTILE_100,
		dataType: METRIC_TYPES.PERCENT
	},
	MOAT_VIEWABLITY: {
		field: "moatViewability",
		width: 145,
		name: lc.METRIC_LABELS.MOAT_VIEWABILITY,
		description: lc.METRICS_TOOLTIPS.MOAT_VIEWABILITY,
		dataType: METRIC_TYPES.PERCENT
	},
	MOAT_AUDIBILITY: {
		field: "moatAudibility",
		width: 135,
		name: lc.METRIC_LABELS.MOAT_AUDIBILITY,
		description: lc.METRICS_TOOLTIPS.MOAT_AUDIBILITY,
		dataType: METRIC_TYPES.PERCENT
	},
	MOAT_VREVENUE: {
		field: "moatVerifiedRevenue",
		width: 160,
		name: lc.METRIC_LABELS.MOAT_V_REVENUE,
		description: lc.METRICS_TOOLTIPS.MOAT_vREVENUE,
		dataType: METRIC_TYPES.CURRENCY
	},
	MOAT_VCPM: {
		field: "moatVerifiedCpm",
		width: 135,
		name: lc.METRIC_LABELS.MOAT_V_CPM,
		description: lc.METRICS_TOOLTIPS.MOAT_vCPM,
		getDerivedValue: row =>
			calculateCPM(row.impressions, row.moatVerifiedRevenue),
		dataType: METRIC_TYPES.CURRENCY
	},
	VIEWABLE: {
		field: "viewablerate",
		width: 100,
		name: lc.METRIC_LABELS.VIEWABLE,
		description: lc.METRICS_TOOLTIPS.VIEWABLE,
		dataType: METRIC_TYPES.PERCENT,
		getDerivedValue: row => row.viewableRate
	},
	DEMAND_PARTNER_FEE: {
		field: "demandPartnerFee",
		width: 190,
		name: lc.METRIC_LABELS.DEMAND_PARTNER_FEE,
		description: lc.METRICS_TOOLTIPS.DEMAND_PARTNER_FEE,
		dataType: METRIC_TYPES.CURRENCY
	},
	DEMAND_OPPORTUNITIES: {
		field: "demandOpportunities",
		width: 185,
		name: lc.METRIC_LABELS.DEMAND_OPPORTUNITIES,
		description: lc.METRICS_TOOLTIPS.DEMAND_OPPORTUNITIES,
		dataType: METRIC_TYPES.NUMBER
	},
	AD_ATTEMPTS: {
		field: "attempts",
		width: 125,
		name: lc.METRIC_LABELS.AD_ATTEMPTS,
		description: lc.METRICS_TOOLTIPS.AD_ATTEMPTS,
		dataType: METRIC_TYPES.NUMBER
	},
	BID_CPM: {
		field: "bidprice",
		width: 120,
		name: lc.METRIC_LABELS.BID_CPM,
		description: lc.METRICS_TOOLTIPS.BID_CPM,
		getDerivedValue: row => calculateCPM(row.rtbBids, row.bidrevenue),
		dataType: METRIC_TYPES.CURRENCY
	},
	BID_REVENUE: {
		field: "bidrevenue",
		width: 140,
		name: lc.METRIC_LABELS.BID_REVENUE,
		description: lc.METRICS_TOOLTIPS.BID_REVENUE,
		dataType: METRIC_TYPES.CURRENCY
	},
	BID_ERRORS: {
		field: "bidErrors",
		width: 105,
		name: lc.METRIC_LABELS.BID_ERRORS,
		description: lc.METRICS_TOOLTIPS.BID_ERRORS,
		dataType: METRIC_TYPES.NUMBER
	},
	TCP_ERRORS: {
		field: "tcpErrors",
		name: lc.METRIC_LABELS.TCP_ERRORS,
		description: lc.METRICS_TOOLTIPS.TCP_ERRORS,
		width: 110,
		dataType: METRIC_TYPES.NUMBER
	},
	TIMEOUTS: {
		field: "timeouts",
		width: 100,
		name: lc.METRIC_LABELS.TIMEOUTS,
		description: lc.METRICS_TOOLTIPS.TIMEOUTS,
		dataType: METRIC_TYPES.NUMBER
	},
	POTENTIAL_TO_FILL: {
		field: "potentialtofill",
		name: lc.METRIC_LABELS.POTENTIAL_TO_FILL,
		description: lc.METRICS_TOOLTIPS.POTENTIAL_FILL_RATE,
		getDerivedValue: row =>
			calculatePotentialFillRate(row.opportunities, row.potentialToFill),
		dataType: METRIC_TYPES.PERCENT
	},
	BID_REQUEST_COST: {
		field: "bidRequestCost",
		name: lc.METRIC_LABELS.BID_REQUEST_COST,
		description: lc.METRICS_TOOLTIPS.BID_REQUEST_COST,
		getDerivedValue: (row, companyConfig) =>
			calculatedCostWithMultiplier(
				row.rtbOpportunities,
				companyConfig.bidRequestMultiplier
			),
		width: 150,
		dataType: METRIC_TYPES.CURRENCY
	},
	OPPORTUNITY_COST: {
		field: "opportunityCost",
		name: lc.METRIC_LABELS.OPPORTUNITY_COST,
		description: lc.METRICS_TOOLTIPS.OPPORTUNITY_COST,
		getDerivedValue: (row, companyConfig) =>
			calculatedCostWithMultiplier(
				row.opportunities,
				companyConfig.opportunityCostMultiplier
			),
		width: 150,
		dataType: METRIC_TYPES.CURRENCY
	},
	OPPORTUNITY_COST_PERCENT: {
		field: "opportunityCostPercent",
		name: lc.METRIC_LABELS.OPPORTUNITY_COST_PERCENT,
		description: lc.METRICS_TOOLTIPS.OPPORTUNITY_COST_PERCENT,
		getDerivedValue: (row, companyConfig) => {
			const opportunityCost = calculatedCostWithMultiplier(
				row.opportunities,
				companyConfig.opportunityCostMultiplier
			);
			return safeDivide(opportunityCost, row.grossrevenue);
		},
		width: 150,
		dataType: METRIC_TYPES.PERCENT
	}
};

export const NETWORK_METRICS = [
	{
		...REPORTING_METRIC.OPPORTUNITIES,
		default: true,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_OPPORTUNITIES"]
	},
	{
		...REPORTING_METRIC.IMPRESSIONS,
		default: true,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_IMPRESSIONS"]
	},
	{
		...REPORTING_METRIC.FILL_RATE,
		default: true,
		permissionsRequired: [
			"VIEW_NETWORK_REPORT_METRIC_OPPORTUNITIES",
			"VIEW_NETWORK_REPORT_METRIC_IMPRESSIONS",
			"VIEW_NETWORK_REPORT_METRIC_FILL_RATE"
		]
	},
	{
		...REPORTING_METRIC.POTENTIAL_TO_FILL,
		permissionsRequired: [
			"VIEW_NETWORK_REPORT_METRIC_IMPRESSIONS",
			"VIEW_NETWORK_REPORT_METRIC_POTENTIAL_FILL_RATE"
		]
	},
	{
		...REPORTING_METRIC.GROSS_CPM,
		default: true,
		permissionsRequired: [
			"VIEW_NETWORK_REPORT_METRIC_IMPRESSIONS",
			"VIEW_NETWORK_REPORT_METRIC_GROSS_REVENUE",
			"VIEW_NETWORK_REPORT_METRIC_GROSS_CPM"
		]
	},
	{
		...REPORTING_METRIC.GROSS_REVENUE,
		default: true,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_GROSS_REVENUE"]
	},
	{
		...REPORTING_METRIC.DEMAND_PARTNER_FEE,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_DEMAND_PARTNER_FEE"]
	},
	{
		...REPORTING_METRIC.PUBLISHER_CPM,
		permissionsRequired: [
			"VIEW_NETWORK_REPORT_METRIC_IMPRESSIONS",
			"VIEW_NETWORK_REPORT_METRIC_PUBLISHER_REVENUE",
			"VIEW_NETWORK_REPORT_METRIC_PUBLISHER_CPM"
		]
	},
	{
		...REPORTING_METRIC.PUBLISHER_REVENUE,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_PUBLISHER_REVENUE"]
	},
	{
		...REPORTING_METRIC.OPPORTUNITY_COST,
		default: true,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_OPPORTUNITY_COST"]
	},
	{
		...REPORTING_METRIC.OPPORTUNITY_COST_PERCENT,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_OPPORTUNITY_COST_PERCENT"]
	},
	{
		...REPORTING_METRIC.NET_MARGIN,
		getDerivedValue: row => safeDivide(row.netRevenue, row.grossrevenue),
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_MARGIN"]
	},
	{
		...REPORTING_METRIC.NET_REVENUE,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_NET_REVENUE"]
	},
	{
		...REPORTING_METRIC.CLICKS,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_CLICKS"]
	},
	{
		...REPORTING_METRIC.CTR,
		permissionsRequired: [
			"VIEW_NETWORK_REPORT_METRIC_CLICKS",
			"VIEW_NETWORK_REPORT_METRIC_IMPRESSIONS",
			"VIEW_NETWORK_REPORT_METRIC_CTR"
		]
	},
	{
		...REPORTING_METRIC.QUARTILE0,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_QUARTILE_0"]
	},
	{
		...REPORTING_METRIC.QUARTILE25,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_QUARTILE_25"]
	},
	{
		...REPORTING_METRIC.QUARTILE50,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_QUARTILE_50"]
	},
	{
		...REPORTING_METRIC.QUARTILE75,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_QUARTILE_75"]
	},
	{
		...REPORTING_METRIC.QUARTILE100,
		default: true,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_QUARTILE_100"]
	},
	{
		...REPORTING_METRIC.MOAT_VIEWABLITY,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_MOAT_VIEWABILITY"]
	},
	{
		...REPORTING_METRIC.MOAT_AUDIBILITY,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_MOAT_AUDIBILITY"]
	},
	{
		...REPORTING_METRIC.MOAT_VREVENUE,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_MOAT_VERIFIED_REVENUE"]
	},
	{
		...REPORTING_METRIC.MOAT_VCPM,
		permissionsRequired: [
			"VIEW_NETWORK_REPORT_METRIC_IMPRESSIONS",
			"VIEW_NETWORK_REPORT_METRIC_MOAT_VERIFIED_REVENUE",
			"VIEW_NETWORK_REPORT_METRIC_MOAT_VERIFIED_CPM"
		]
	},
	{
		...REPORTING_METRIC.VIEWABLE,
		permissionsRequired: ["VIEW_NETWORK_REPORT_METRIC_VIEWABLE_RATE"]
	}
];

export const CAMPAIGN_METRICS = [
	{
		...REPORTING_METRIC.DEMAND_OPPORTUNITIES,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_DEMAND_OPPORTUNITIES"]
	},
	{
		...REPORTING_METRIC.AD_ATTEMPTS,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_ATTEMPTS"]
	},
	{
		...REPORTING_METRIC.IMPRESSIONS,
		default: true,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_IMPRESSIONS"]
	},
	{
		...REPORTING_METRIC.FILL_RATE,
		getDerivedValue: row => safeDivide(row.impressions, row.attempts),
		permissionsRequired: [
			"VIEW_CAMPAIGN_REPORT_METRIC_ATTEMPTS",
			"VIEW_CAMPAIGN_REPORT_METRIC_IMPRESSIONS",
			"VIEW_CAMPAIGN_REPORT_METRIC_FILL_RATE"
		]
	},
	{
		...REPORTING_METRIC.GROSS_CPM,
		default: true,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_GROSS_CPM"]
	},
	{
		...REPORTING_METRIC.GROSS_REVENUE,
		default: true,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_GROSS_REVENUE"]
	},
	{
		...REPORTING_METRIC.DEMAND_PARTNER_FEE,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_DEMAND_PARTNER_FEE"]
	},
	{
		...REPORTING_METRIC.PUBLISHER_CPM,
		permissionsRequired: [
			"VIEW_CAMPAIGN_REPORT_METRIC_PUBLISHER_REVENUE",
			"VIEW_CAMPAIGN_REPORT_METRIC_IMPRESSIONS",
			"VIEW_CAMPAIGN_REPORT_METRIC_PUBLISHER_CPM"
		]
	},
	{
		...REPORTING_METRIC.PUBLISHER_REVENUE,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_PUBLISHER_REVENUE"]
	},
	{
		...REPORTING_METRIC.NET_MARGIN,
		getDerivedValue: row => safeDivide(row.netRevenue, row.grossrevenue),
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_MARGIN"]
	},
	{
		...REPORTING_METRIC.NET_REVENUE,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_NET_REVENUE"]
	},
	{
		...REPORTING_METRIC.CLICKS,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_CLICKS"]
	},
	{
		...REPORTING_METRIC.CTR,
		permissionsRequired: [
			"VIEW_CAMPAIGN_REPORT_METRIC_CLICKS",
			"VIEW_CAMPAIGN_REPORT_METRIC_IMPRESSIONS",
			"VIEW_CAMPAIGN_REPORT_METRIC_CTR"
		]
	},
	{
		...REPORTING_METRIC.QUARTILE0,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_QUARTILE_0"]
	},
	{
		...REPORTING_METRIC.QUARTILE25,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_QUARTILE_25"]
	},
	{
		...REPORTING_METRIC.QUARTILE50,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_QUARTILE_50"]
	},
	{
		...REPORTING_METRIC.QUARTILE75,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_QUARTILE_75"]
	},
	{
		...REPORTING_METRIC.QUARTILE100,
		default: true,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_QUARTILE_100"]
	},
	{
		...REPORTING_METRIC.MOAT_VIEWABLITY,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_MOAT_VIEWABILITY"]
	},
	{
		...REPORTING_METRIC.MOAT_AUDIBILITY,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_MOAT_AUDIBILITY"]
	},
	{
		...REPORTING_METRIC.MOAT_VREVENUE,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_MOAT_VERIFIED_REVENUE"]
	},
	{
		...REPORTING_METRIC.MOAT_VCPM,
		permissionsRequired: [
			"VIEW_CAMPAIGN_REPORT_METRIC_IMPRESSIONS",
			"VIEW_CAMPAIGN_REPORT_METRIC_MOAT_VERIFIED_REVENUE",
			"VIEW_CAMPAIGN_REPORT_METRIC_MOAT_VERIFIED_CPM"
		]
	},
	{
		...REPORTING_METRIC.VIEWABLE,
		permissionsRequired: ["VIEW_CAMPAIGN_REPORT_METRIC_VIEWABLE_RATE"]
	}
];
export const RTB_METRICS = [
	{
		...REPORTING_METRIC.RTB_OPPORTUNITIES,
		default: true,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_RTB_OPPS"]
	},
	{
		...REPORTING_METRIC.RTB_BIDS,
		default: true,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_BIDS"]
	},
	{
		...REPORTING_METRIC.WINS,
		default: true,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_WINS"]
	},
	{
		...REPORTING_METRIC.IMPRESSIONS,
		default: true,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_IMPRESSIONS"]
	},
	{
		...REPORTING_METRIC.BID_RATE,
		default: true,
		permissionsRequired: [
			"VIEW_RTB_REPORT_METRIC_RTB_OPPS",
			"VIEW_RTB_REPORT_METRIC_BIDS",
			"VIEW_RTB_REPORT_METRIC_BID_RATE"
		]
	},
	{
		...REPORTING_METRIC.WIN_RATE,
		default: true,
		permissionsRequired: [
			"VIEW_RTB_REPORT_METRIC_WINS",
			"VIEW_RTB_REPORT_METRIC_BIDS",
			"VIEW_RTB_REPORT_METRIC_WIN_RATE"
		]
	},
	{
		...REPORTING_METRIC.CLOSE_RATE,
		default: true,
		permissionsRequired: [
			"VIEW_RTB_REPORT_METRIC_WINS",
			"VIEW_RTB_REPORT_METRIC_IMPRESSIONS",
			"VIEW_RTB_REPORT_METRIC_CLOSE_RATE"
		]
	},
	{
		...REPORTING_METRIC.CLOSE_CPM,
		default: true,
		permissionsRequired: [
			"VIEW_RTB_REPORT_METRIC_IMPRESSIONS",
			"VIEW_RTB_REPORT_METRIC_WIN_PRICE",
			"VIEW_RTB_REPORT_METRIC_WIN_REVENUE"
		]
	},
	{
		...REPORTING_METRIC.CLOSE_REVENUE,
		default: true,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_WIN_REVENUE"]
	},
	{
		...REPORTING_METRIC.DEMAND_PARTNER_FEE,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_DEMAND_PARTNER_FEE"]
	},
	{
		...REPORTING_METRIC.BID_CPM,
		permissionsRequired: [
			"VIEW_RTB_REPORT_METRIC_BIDS",
			"VIEW_RTB_REPORT_METRIC_BID_REVENUE",
			"VIEW_RTB_REPORT_METRIC_BID_PRICE"
		]
	},
	{
		...REPORTING_METRIC.BID_REVENUE,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_BID_REVENUE"]
	},
	{
		...REPORTING_METRIC.PUBLISHER_CPM,
		permissionsRequired: [
			"VIEW_RTB_REPORT_METRIC_IMPRESSIONS",
			"VIEW_RTB_REPORT_METRIC_PUBLISHER_REVENUE",
			"VIEW_RTB_REPORT_METRIC_PUBLISHER_CPM"
		]
	},
	{
		...REPORTING_METRIC.PUBLISHER_REVENUE,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_PUBLISHER_REVENUE"]
	},
	{
		...REPORTING_METRIC.BID_REQUEST_COST,
		default: true,
		permissionsRequired: [
			"VIEW_RTB_REPORT_METRIC_BID_REQUEST_COST",
			"EDIT_BID_REQUEST_COST_MULTIPLIER"
		]
	},
	{
		...REPORTING_METRIC.NET_MARGIN,
		getDerivedValue: row => safeDivide(row.netRevenue, row.winrevenue),
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_MARGIN"]
	},
	{
		...REPORTING_METRIC.NET_REVENUE,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_NET_REVENUE"]
	},
	{
		...REPORTING_METRIC.QUARTILE0,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_QUARTILE_0"]
	},
	{
		...REPORTING_METRIC.QUARTILE25,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_QUARTILE_25"]
	},
	{
		...REPORTING_METRIC.QUARTILE50,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_QUARTILE_50"]
	},
	{
		...REPORTING_METRIC.QUARTILE75,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_QUARTILE_75"]
	},
	{
		...REPORTING_METRIC.QUARTILE100,
		default: true,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_QUARTILE_100"]
	},
	{
		...REPORTING_METRIC.BID_ERRORS,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_BID_ERRORS"]
	},
	{
		...REPORTING_METRIC.TCP_ERRORS,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_TCP_ERRORS"]
	},
	{
		...REPORTING_METRIC.TIMEOUTS,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_TIMEOUTS"]
	},
	{
		...REPORTING_METRIC.MOAT_VIEWABLITY,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_MOAT_VIEWABILITY"]
	},
	{
		...REPORTING_METRIC.MOAT_AUDIBILITY,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_MOAT_AUDIBILITY"]
	},
	{
		...REPORTING_METRIC.MOAT_VREVENUE,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_MOAT_VERIFIED_REVENUE"]
	},
	{
		...REPORTING_METRIC.MOAT_VCPM,
		permissionsRequired: [
			"VIEW_RTB_REPORT_METRIC_MOAT_VERIFIED_REVENUE",
			"VIEW_RTB_REPORT_METRIC_IMPRESSIONS",
			"VIEW_RTB_REPORT_METRIC_MOAT_VERIFIED_CPM"
		]
	},
	{
		...REPORTING_METRIC.VIEWABLE,
		permissionsRequired: ["VIEW_RTB_REPORT_METRIC_VIEWABLE_RATE"]
	}
];

function getMetricName(metric, isDemandClient) {
	if (isDemandClient) {
		return metric.demandClientName || metric.name;
	}
	return metric.name;
}

export const getMetricsConfigByType = (type, options = {}) => {
	const { isDemandClient } = options;
	let metrics;
	switch (type) {
		case REPORT_TYPE_OPTIONS_ENUM.network.value:
			metrics = NETWORK_METRICS;
			break;
		case REPORT_TYPE_OPTIONS_ENUM.campaign.value:
			metrics = CAMPAIGN_METRICS;
			break;
		case REPORT_TYPE_OPTIONS_ENUM.rtb.value:
			metrics = RTB_METRICS;
			break;
		default:
			metrics = [];
			break;
	}

	return metrics.map(metricConfig => ({
		...metricsHeaderDefaults,
		valueFormatter: VALUE_FORMATTERS[metricConfig.dataType], // Add value formatter for use in the data table and KPIS, but allow it to be overridden by the metric config
		...metricConfig,
		name: getMetricName(metricConfig, isDemandClient)
	}));
};
