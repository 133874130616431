import React from "react";
import CrudIndex from "../../components/CrudIndex/CrudIndex";
import commonLocaleContent from "../commonLocaleContent";
import { buildFormatNullValues } from "../../utils/formatters";
import localeContent from "./localeContent";
import { COLUMN_KEY_STATUS } from "../../components/CrudDataTable/CrudDataTable";

const programmaticDemandColumnsConfig = [
	{
		key: "dealId",
		name: localeContent.COLUMN_HEADER_DEAL_ID,
		formatter: buildFormatNullValues()
	},
	{
		key: COLUMN_KEY_STATUS,
		name: commonLocaleContent.COLUMN_HEADER_STATUS,
		cellDataType: "upperCase"
	}
];

function ProgrammaticDemandIndex() {
	return (
		<CrudIndex
			resourceName={commonLocaleContent.PROGRAMMATIC_DEMAND}
			columnsConfig={programmaticDemandColumnsConfig}
			resourceEndpoint="manage/programmatic-demand"
			resourceRoute="programmatic-demand"
			resourceNamePlural={commonLocaleContent.PROGRAMMATIC_DEMAND}
			showDataGrid
		/>
	);
}

export default ProgrammaticDemandIndex;
