import React from "react";
import * as Yup from "yup";
import { format } from "date-fns";

import CrudForm from "../../components/CrudForm/CrudForm";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import lc from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import commonFieldOptions from "../commonFieldOptions";
import { SERVER_DATE_FORMAT } from "../../components/FormFieldDateTimePicker/FormFieldDateTimePicker";
import AlignmentTool from "../../components/AlignmentTool/AlignmentTool";
import PLACEMENT_ALIGNMENT_COLUMNS from "../../components/AlignmentTool/sharedColumnsConfig/placementAlignmentColumns";
import Operators from "../../components/FormFieldExpressionBuilder/Operators";
import validators from "../../utils/validators";
import { buildValidationSchema } from "../../components/FormFieldExpressionBuilder/FormFieldExpressionBuilder";
import {
	AUCTION_TYPE_AGREED_UPON_DEAL_PRICE,
	EXPRESSION_NODE_TYPE
} from "../../config/constants";
import campaignLC from "../Campaign/localeContent";
import {
	DEFAULT_FREQUENCY_CAPPING_PERIOD,
	FREQUENCY_CAPPING_PERIOD_OPTIONS
} from "../Campaign/Resource";
import CustomUrlParamsFieldDefinition from "../../components/CrudForm/sharedFieldConfigDefinitions/CustomUrlParams/CustomUrlParamsFieldDefinition";

export const ENABLE_DEAL_KEY = "enableDeal";
const SEAT_TYPE_KEY = "seatType";
const WSEAT_KEY = "wseat";
export const AUCTION_TYPES = [
	{
		value: "FIRST_PRICE",
		label: lc.FIELD_AUCTION_TYPE.OPTION_LABELS.FIRST_PRICE
	},
	{
		value: "SECOND_PRICE",
		label: lc.FIELD_AUCTION_TYPE.OPTION_LABELS.SECOND_PRICE
	},
	{
		value: AUCTION_TYPE_AGREED_UPON_DEAL_PRICE,
		label: lc.FIELD_AUCTION_TYPE.OPTION_LABELS.AGREED_UPON_DEAL_PRICE
	}
];
const MAX_FLOOR_VALUE = 100;
const DEAL_GOAL_TYPE_IMPRESSIONS = "impressions";
const DEAL_GOAL_TYPE_SPEND = "spend";
const DEAL_GOAL_TYPE_OPEN = "open";
export const DEAL_GOAL_TYPES = [
	{
		value: DEAL_GOAL_TYPE_IMPRESSIONS,
		label: lc.FIELD_DEAL_GOAL_TYPE.OPTION_LABELS.IMPRESSION
	},
	{
		value: DEAL_GOAL_TYPE_SPEND,
		label: lc.FIELD_DEAL_GOAL_TYPE.OPTION_LABELS.SPEND
	},
	{
		value: DEAL_GOAL_TYPE_OPEN,
		label: lc.FIELD_DEAL_GOAL_TYPE.OPTION_LABELS.OPEN
	}
];

const TABS_CONFIG = [
	{
		key: "SETTINGS",
		label: lc.TAB_LABEL_SETTINGS,
		fieldsConfig: [
			{
				key: "BASIC_SETTINGS",
				fields: [
					{
						name: "name",
						label: lc.FIELD_PROGRAMMATIC_DEMAND_NAME.LABEL,
						isRequired: true,
						gridConfig: { md: 8 }
					},
					{
						name: "status",
						label: lc.FIELD_STATUS.LABEL,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: commonFieldOptions.mapEnumToFieldOptions(
								commonFieldOptions.STATUS_ENUM
							)
						},
						defaultValue: commonFieldOptions.STATUS_ENUM.active.value
					}
				]
			},
			{
				key: "BID_SETTINGS",
				fields: [
					{
						name: ENABLE_DEAL_KEY,
						label: lc.FIELD_ENABLE_DEAL.LABEL,
						tooltip: lc.FIELD_ENABLE_DEAL.TOOLTIP,
						gridConfig: { md: 2 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false,
						disableOnEdit: true
					},
					{
						name: "privateDeal",
						renderIf: formValues => formValues[ENABLE_DEAL_KEY],
						label: lc.FIELD_PRIVATE_AUCTION.LABEL,
						tooltip: lc.FIELD_PRIVATE_AUCTION.TOOLTIP,
						gridConfig: { md: 3, lg: 2 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false
					},
					{
						name: "auctionType",
						renderIf: formValues => formValues[ENABLE_DEAL_KEY],
						label: lc.FIELD_AUCTION_TYPE.LABEL,
						tooltip: lc.FIELD_AUCTION_TYPE.TOOLTIP,
						gridConfig: { md: 3, lg: 4 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: AUCTION_TYPES
						},
						defaultValue: "FIRST_PRICE"
					},
					{
						name: "dealId",
						renderIf: formValues => formValues[ENABLE_DEAL_KEY],
						label: lc.FIELD_DEAL_ID.LABEL,
						tooltip: lc.FIELD_DEAL_ID.TOOLTIP,
						gridConfig: { md: 4 },
						isRequiredIfRendered: true,
						validationSchema: Yup.string()
							.when(ENABLE_DEAL_KEY, {
								is: true,
								then: schema =>
									schema
										.required(
											`Required when ${lc.FIELD_ENABLE_DEAL.LABEL} is enabled`
										)
										.matches(
											validators.validationPatternAlphanumericWithUnderscoreDashAndPeriod,
											lc.FIELD_DEAL_ID
												.NON_ALPHANUMERIC_PERIOD_AND_UNDERCORE_VALUE_ERROR
										)
							})
							.nullable(),
						defaultValue: ""
					},
					{
						name: SEAT_TYPE_KEY,
						renderIf: formValues => !formValues[ENABLE_DEAL_KEY],
						disableIf: formValues => {
							let disabled = true;
							if (
								!formValues[ENABLE_DEAL_KEY] &&
								formValues.programmaticBidderConfigs.some(
									bidderConfig => bidderConfig.rtbBidderSeats.length > 0
								)
							) {
								disabled = false;
							}
							return disabled;
						},
						label: lc.FIELD_SEAT_TYPE.LABEL,
						tooltip: lc.FIELD_SEAT_TYPE.TOOLTIP,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: [
								{
									label: lc.FIELD_SEAT_TYPE.OPTION_LABELS.WSEAT,
									value: WSEAT_KEY
								},
								{
									label: lc.FIELD_SEAT_TYPE.OPTION_LABELS.BSEAT,
									value: "bseat"
								}
							]
						},
						defaultValue: WSEAT_KEY
					},
					{
						name: "cloneAlignments",
						label: lc.CLONE_ALIGNMENTS.LABEL,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: true,
						tooltip: lc.CLONE_ALIGNMENTS.TOOLTIP,
						renderIf: (formValues, initData, resourceId, isClonedResource) =>
							isClonedResource
					},
					{
						name: "dealGoal",
						renderIf: formValues =>
							formValues[ENABLE_DEAL_KEY] &&
							formValues.privateDeal &&
							formValues.auctionType === AUCTION_TYPE_AGREED_UPON_DEAL_PRICE,
						label: lc.FIELD_DEAL_GOAL_TYPE.LABEL,
						tooltip: lc.FIELD_DEAL_GOAL_TYPE.TOOLTIP,
						gridConfig: { md: 4, sm: 6 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: DEAL_GOAL_TYPES
						},
						defaultValue: DEAL_GOAL_TYPE_OPEN
					},
					{
						name: "impressionsTarget",
						renderIf: formValues =>
							formValues[ENABLE_DEAL_KEY] &&
							formValues.privateDeal &&
							formValues.auctionType === AUCTION_TYPE_AGREED_UPON_DEAL_PRICE &&
							formValues.dealGoal === DEAL_GOAL_TYPE_IMPRESSIONS,
						label: lc.FIELD_DEAL_GOAL_TARGET.LABEL,
						tooltip: lc.FIELD_DEAL_GOAL_TARGET.TOOLTIP,
						gridConfig: { md: 4, sm: 6 },
						fieldType: FieldTypes.NUMBER,
						fieldTypeOptions: {
							placeholder: lc.FIELD_DEAL_GOAL_TARGET.PLACEHOLDER
						},
						isRequiredIfRendered: true,
						validationSchema: Yup.number().when(
							[ENABLE_DEAL_KEY, "privateDeal", "auctionType", "dealGoal"],
							{
								is: (enableDeal, privateDeal, auctionType, dealGoal) =>
									enableDeal &&
									privateDeal &&
									auctionType === AUCTION_TYPE_AGREED_UPON_DEAL_PRICE &&
									dealGoal === DEAL_GOAL_TYPE_IMPRESSIONS,
								then: schema =>
									schema.required(lc.FIELD_DEAL_GOAL_TARGET.REQUIRED_ERROR)
							}
						)
					},
					{
						name: "spendTarget",
						renderIf: formValues =>
							formValues[ENABLE_DEAL_KEY] &&
							formValues.privateDeal &&
							formValues.auctionType === AUCTION_TYPE_AGREED_UPON_DEAL_PRICE &&
							formValues.dealGoal === DEAL_GOAL_TYPE_SPEND,
						label: lc.FIELD_DEAL_GOAL_TARGET.LABEL,
						tooltip: lc.FIELD_DEAL_GOAL_TARGET.TOOLTIP,
						gridConfig: { md: 4, sm: 6 },
						fieldType: FieldTypes.CURRENCY,
						isRequiredIfRendered: true,
						validationSchema: Yup.number().when(
							[ENABLE_DEAL_KEY, "privateDeal", "auctionType", "dealGoal"],
							{
								is: (enableDeal, privateDeal, auctionType, dealGoal) =>
									enableDeal &&
									privateDeal &&
									auctionType === AUCTION_TYPE_AGREED_UPON_DEAL_PRICE &&
									dealGoal === DEAL_GOAL_TYPE_SPEND,
								then: schema =>
									schema.required(lc.FIELD_DEAL_GOAL_TARGET.REQUIRED_ERROR)
							}
						)
					},
					{
						name: "enableFrequencyCapping",
						label: campaignLC.ENABLE_FREQUENCY_CAPPING_LABEL,
						tooltip: lc.ENABLE_FREQUENCY_CAPPING_TOOLTIP,
						getGridConfig: formValues => ({
							md: 4,
							...(formValues.goal === "open" ? { offset: { md: 7 } } : {})
						}),
						fieldType: FieldTypes.SWITCH,
						renderIf: formValues => formValues.privateDeal,
						defaultValue: false
					},
					{
						name: "frequencyCappingAmount",
						label: campaignLC.FREQUENCY_CAPPING_AMOUNT_LABEL,
						tooltip: lc.IMPRESSION_PER_USER_TOOLTIP,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.NUMBER,
						renderIf: formValues =>
							formValues.privateDeal && formValues.enableFrequencyCapping,
						validationSchema: Yup.number().when(
							["privateDeal", "enableFrequencyCapping"],
							{
								is: (privateDeal, enableFrequencyCapping) =>
									enableFrequencyCapping === true && privateDeal === true,
								then: Yup.number().required(
									campaignLC.FREQUENCY_CAPPING_AMOUNT_REQUIRED
								)
							}
						)
					},
					{
						name: "frequencyCappingPeriod",
						label: campaignLC.FREQUENCY_CAPPING_PERIOD_LABEL,
						tooltip: lc.TIMEFRAME_TOOLTIP,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: FREQUENCY_CAPPING_PERIOD_OPTIONS
						},
						defaultValue: DEFAULT_FREQUENCY_CAPPING_PERIOD,
						renderIf: formValues =>
							formValues.privateDeal && formValues.enableFrequencyCapping
					},
					{
						name: "programmaticBidderConfigs",
						fieldType: FieldTypes.BIDDER_CONFIG,
						defaultValue: [],
						fieldTypeOptions: {
							seatTypeKey: SEAT_TYPE_KEY,
							wSeatKey: WSEAT_KEY
						}
					},
					{
						name: "startDate",
						label: lc.FIELD_START_DATE.LABEL,
						tooltip: lc.FIELD_START_DATE.TOOLTIP,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.DATE_TIME_PICKER,
						isRequired: true,
						fieldTypeOptions: {
							showTodayButton: true
						},
						defaultValue: format(new Date(), SERVER_DATE_FORMAT)
					},
					{
						name: "endDate",
						label: lc.FIELD_END_DATE.LABEL,
						tooltip: lc.FIELD_END_DATE.TOOLTIP,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.DATE_TIME_PICKER,
						fieldTypeOptions: {
							clearable: true
						},
						defaultValue: null,
						validationSchema: Yup.date().when(
							[ENABLE_DEAL_KEY, "privateDeal", "auctionType", "dealGoal"],
							{
								is: (enableDeal, privateDeal, auctionType, dealGoal) =>
									enableDeal &&
									privateDeal &&
									auctionType === AUCTION_TYPE_AGREED_UPON_DEAL_PRICE &&
									dealGoal !== DEAL_GOAL_TYPE_OPEN,
								then: schema =>
									schema.required(lc.FIELD_END_DATE.REQUIRED_ERROR)
							}
						)
					},
					{
						name: "floor",
						renderIf: formValues => formValues[ENABLE_DEAL_KEY],
						label: lc.FIELD_FLOOR.LABEL,
						tooltip: lc.FIELD_FLOOR.TOOLTIP,
						fieldType: FieldTypes.CURRENCY,
						gridConfig: { md: 4 },
						isRequiredIfRendered: true,
						validationSchema: Yup.number().when(ENABLE_DEAL_KEY, {
							is: true,
							then: schema =>
								schema
									.required(
										lc.FIELD_FLOOR.REQUIRED_ERROR({
											enableDealLabel: lc.FIELD_ENABLE_DEAL.LABEL
										})
									)
									.max(MAX_FLOOR_VALUE, lc.FIELD_FLOOR.MAX_VALUE_ERROR)
						})
					}
				]
			},
			{
				key: "PRIORITY_SETTINGS",
				fields: [
					{
						name: "priority",
						label: lc.FIELD_PRIORITY.LABEL,
						tooltip: lc.FIELD_PRIORITY.TOOLTIP,
						gridConfig: { md: 3, lg: 2 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: [...commonFieldOptions.PRIORITY]
						},
						defaultValue: ""
					},
					{
						name: "weight",
						label: lc.FIELD_WEIGHT.LABEL,
						tooltip: lc.FIELD_WEIGHT.TOOLTIP,
						gridConfig: { md: 3, lg: 2 },
						fieldType: FieldTypes.SELECT,
						optionsConfig: {
							options: [...commonFieldOptions.WEIGHT]
						},
						defaultValue: ""
					}
				]
			}
		]
	},
	{
		label: lc.TAB_LABEL_QUALITY,
		key: "QUALITY",
		fieldsConfig: [
			{
				key: "TRACKERS",
				fields: [
					{
						name: "trackers",
						label: lc.FIELD_TRACKERS.LABEL,
						gridConfig: { md: 8, lg: 6 },
						fieldType: FieldTypes.MULTISELECT,
						permissionRequired: "VIEW_MEDIA_TRACKER",
						optionsConfig: {
							requestEndpoint: "manage/trackers",
							requestParams: { mediaTrackers: true, impressionOnly: true }
						},
						defaultValue: []
					},
					{
						name: "ivtFilteringRequired",
						label: lc.FIELD_REQUIRE_IVT_FILTERING.LABEL,
						tooltip: lc.FIELD_REQUIRE_IVT_FILTERING.TOOLTIP,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{
									label: commonLocaleContent.OPTION_LABEL_ENABLED,
									value: true
								},
								{
									label: commonLocaleContent.OPTION_LABEL_DISABLED,
									value: false
								}
							]
						},
						defaultValue: false
					}
				]
			},
			{
				key: "GEO_TARGETING_SETTINGS",
				fields: [
					{
						name: "geoTargetingConfig",
						fieldType: FieldTypes.GEO_TARGETING
					}
				]
			},
			{
				key: "DEVICE_TARGETING_SETTINGS",
				fields: [
					{
						name: "deviceTargeting",
						fieldType: FieldTypes.DEVICE_TARGETING,
						defaultValue: { devices: [] }
					}
				]
			},
			{
				key: "SIZE_TARGETING_SETTINGS",
				fields: [
					{
						name: "sizeTargeting",
						fieldType: FieldTypes.SIZE_TARGETING
					}
				]
			},
			{
				key: "LIST_TARGETING_SETTINGS",
				fields: [
					{
						name: "blacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel: lc.TARGETING_TYPE_DOMAIN_LABEL,
							validatorFunction: validators.validateDomain
						},
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					},
					{
						name: "appNameBlacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel: lc.TARGETING_TYPE_APP_NAME_LABEL,
							validatorFunction: validators.validateUTF8
						},
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					},
					{
						name: "appBundleIdBlacklistWhitelist",
						fieldTypeOptions: {
							targetingTypeLabel: lc.TARGETING_TYPE_APP_BUNDLE_IDS_LABEL,
							validatorFunction: validators.validateUTF8
						},
						fieldType: FieldTypes.ACCESS_RESTRICTION_FREEFORM
					}
				]
			},
			{
				key: "CUSTOM_QUERY_PARAMETER",
				fields: [CustomUrlParamsFieldDefinition]
			},
			{
				key: "AUDIENCE_BUILDER",
				permissionRequired: "VIEW_AUDIENCE_MENU",
				fields: [
					{
						name: "audienceExpression",
						fieldType: FieldTypes.EXPRESSION_BUILDER,
						validationSchema: buildValidationSchema({
							leafName: lc.FIELD_AUDIENCE.LEAF_NAME_LABEL,
							leafNodeKey: EXPRESSION_NODE_TYPE.AUDIENCE,
							canEnable: true
						}),
						fieldTypeOptions: {
							leafOptionsConfig: {
								leafOptionsEndpoint: "manage/audiences",
								leafOptionIdKey: "id",
								existingLeafField: "audiences",
								leafTableColumnsConfig: [
									{
										Header: lc.FIELD_AUDIENCE.LEAF_COLUMN_HEADERS.NAME,
										accessor: "name",
										isSortable: true
									},
									{
										Header: lc.FIELD_AUDIENCE.LEAF_COLUMN_HEADERS.ESTIMATED_CPM,
										accessor: "cpm",
										align: "right"
									},
									{
										Header:
											lc.FIELD_AUDIENCE.LEAF_COLUMN_HEADERS
												.POTENTIAL_COOKIE_REACH,
										accessor: "potentialReach",
										align: "right"
									},
									{
										Header:
											lc.FIELD_AUDIENCE.LEAF_COLUMN_HEADERS
												.POTENTIAL_DEVICE_REACH,
										accessor: "potentialDeviceReach",
										align: "right"
									}
								]
							},
							leafExpressionNodeRepresentation: {
								nodeType: EXPRESSION_NODE_TYPE.AUDIENCE,
								idKey: "audienceId",
								labelKey: "name"
							},
							emptyExpressionWarning:
								lc.FIELD_AUDIENCE.EMPTY_EXPRESSION_WARNING,
							enableFieldLabel: lc.FIELD_AUDIENCE.ENABLE_FIELD_LABEL,
							suppressOperators: [Operators.NOT]
						}
					}
				]
			},
			{
				key: "DAY_PARTING_SETTINGS",
				fields: [
					{
						name: "dayParting",
						fieldType: FieldTypes.DAY_PARTING
					}
				]
			}
		]
	},
	{
		key: "ALIGNMENTS",
		label: lc.TAB_LABEL_ALIGNMENTS,
		disableOnCreate: true,
		useContainer: false,
		componentConfig: {
			Component: AlignmentTool,
			props: {
				columns: PLACEMENT_ALIGNMENT_COLUMNS,
				tableOptionLabelAvailable: "Available Placements",
				tableOptionLabelAligned: "Aligned Placements",
				availableOptionsEndpoint: "manage/alignment-options/placements",
				availableOptionsIdKey: "programmaticId",
				alignmentEndpoint: "programmatic-demand",
				entityTypeLabel: commonLocaleContent.PROGRAMMATIC_DEMAND,
				defaultShownColumns: ["name", "floor", "margin"],
				mapAlignedOptions: alignmentConfig => alignmentConfig?.placements || [],
				onSave: placements => ({
					placements
				})
			}
		}
	}
];

function ProgrammaticDemandResourceScreen() {
	const cloneDayParting = data => {
		const dayParting = data ? data.dayParting : null;
		return dayParting ? { ...dayParting, id: "" } : null;
	};

	return (
		<CrudForm
			resourceEndpoint="manage/programmatic-demand"
			resourceRoute="programmatic-demand"
			resourceString={commonLocaleContent.PROGRAMMATIC_DEMAND}
			tabsConfig={TABS_CONFIG}
			mapCloneDataOverride={data => {
				return {
					...data,
					name: "",
					dayParting: cloneDayParting(data)
				};
			}}
			preSubmit={(formValues, resourceId) => {
				if (!formValues.privateDeal) formValues.enableFrequencyCapping = false;
				return {
					...formValues,
					id: resourceId
				};
			}}
			prepopulateCreate
			cloneUrlWithId
		/>
	);
}

export default ProgrammaticDemandResourceScreen;
